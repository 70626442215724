import { AbczStatus, Typography, TypographyVariant } from 'components/atoms'

import { AbczSyncStatus } from 'app/core/types/abcz'
import { AnimalHistoryProps } from 'app/core/types/animal'

import { Col } from '../infocard'

type AbczEntryProps = {
  history: AnimalHistoryProps
}

const AbczStatusEntry: React.FC<AbczEntryProps> = ({ history }) => {
  const abczMessage = history.data.find(item => item.field === 'status_message')
    ?.value as string
  const abczStatus = history.data.find(item => item.field === 'abcz_status')
    ?.value as AbczSyncStatus

  return (
    <>
      <Col>
        <Typography
          text="Status ABCZ"
          variant={TypographyVariant.caption3}
          altColor
        />
        <AbczStatus status={abczStatus} message={abczMessage} />
      </Col>
    </>
  )
}

export { AbczStatusEntry }
