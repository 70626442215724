import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useMemo, useState } from 'react'

import * as yup from 'yup'

import { ButtonType, IconNames } from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal, ExpandCard } from 'components/molecules'
import { BoxedTable, useForm } from 'components/organisms'

import { AnimalSpecies } from 'app/core/types/animal'
import { BreedHook } from 'app/core/types/hooks'
import { BreedCreateRequestData, BreedProps } from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './../../styles.module.scss'
import { breedColumns } from './table'

const isEuropeanOption = 'Sim'

const BreedCard: React.FC<BreedHook> = ({
  breeds,
  deleteBreed,
  addBreed,
  updateBreed,
  readAllBreeds,
}) => {
  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState<BreedProps | undefined>()
  const [patchItem, setPatchItem] = useState<BreedProps | undefined>()

  const handleDeleteModalConfirm = async (): Promise<void> => {
    try {
      const id = deleteItem?.id
      if (!id) {
        throw new Error('Missing delete item id')
      }
      handleDeleteModalClose()
      await deleteBreed(id)
      await readAllBreeds()
    } catch (err) {
      addToast({ message: 'Aconteceu um erro inesperado', type: 'error' })
    }
  }

  const handleDeleteModalClose = (): void => {
    setDeleteItem(undefined)
  }

  const handleUpsertModalClose = (): void => {
    setPatchItem(undefined)
    setIsUpsertModalOpen(false)
  }

  const handleAddClick = (): void => {
    setIsUpsertModalOpen(true)
    reset()
  }

  const handleFormSubmit = useCallback(
    async (data: unknown): Promise<void> => {
      try {
        const id = patchItem?.id
        const payload = {
          description: (data as { description: string }).description,
          abbreviation: (data as { abbreviation: string }).abbreviation,
          specie: (data as { specie: { value: string } }).specie.value,
          is_european:
            (data as { is_european: string | null }).is_european ===
            isEuropeanOption,
        }
        handleUpsertModalClose()
        if (!id) {
          await addBreed(payload as BreedCreateRequestData)
          await readAllBreeds()
          return
        }
        await updateBreed(id, payload as Partial<BreedCreateRequestData>)
        await readAllBreeds()
      } catch (err) {
        addToast({ message: 'Aconteceu um erro inesperado', type: 'error' })
      }
    },
    [addBreed, patchItem?.id, readAllBreeds, updateBreed]
  )

  const validationSchema = yup.object({
    specie: yup
      .object({
        value: yup.string(),
        label: yup.string(),
      })
      .required(Messages.YUP_REQUIRED_FIELD),
    description: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    abbreviation: yup
      .string()
      .required(Messages.YUP_REQUIRED_FIELD)
      .max(3, Messages.SYSTEM_BREED_ABBREVIATION_CHARS_LIMIT_MAX)
      .min(2, Messages.SYSTEM_BREED_ABBREVIATION_CHARS_LIMIT_MIN),
    is_european: yup.string().nullable(),
  })

  const { Form, handleSubmit, setValue, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleEditClick = useCallback(
    (item: BreedProps): void => {
      reset()
      setValue('specie', { value: item.specie, label: item.specie })
      setValue('description', item.description)
      setValue('abbreviation', item.abbreviation)
      setValue('is_european', item.is_european ? isEuropeanOption : null)
      setIsUpsertModalOpen(true)
      setPatchItem(item)
    },
    [reset, setValue]
  )

  const handleRemoveClick = useCallback((item: BreedProps): void => {
    setDeleteItem(item)
  }, [])

  const columns = useMemo<ColumnDef<BreedProps>[]>(
    () => breedColumns(handleEditClick, handleRemoveClick),
    [handleEditClick, handleRemoveClick]
  )

  const table = useReactTable({
    data: breeds ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <ExpandCard className={styles.settingsExpandCard} title="Raças">
        <div className={styles.cardContent}>
          <Divider darker />

          <BoxedTable
            className={styles.settingsBoxedTable}
            data={breeds ?? []}
            title=""
            quantityLabel="Raça"
            table={table}
            mainButtonLabel="Incluir"
            mainButtonIcon={IconNames['add-circle']}
            mainButtonAction={handleAddClick}
            disableNavigation
          />
        </div>

        <CustomModal
          modalIsOpen={!!isUpsertModalOpen}
          handleCloseModal={handleUpsertModalClose}
          modalHeader={patchItem ? 'Editar Raça' : 'Incluir Raça'}
          primaryButtonLabel="SALVAR"
          primaryButtonAction={handleSubmit(handleFormSubmit)}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleUpsertModalClose}
        >
          <div className={styles.form}>
            <Form>
              <div className={styles.formFieldset}>
                <Form.Select
                  label="Espécie"
                  name="specie"
                  options={Object.values(AnimalSpecies).map(item => ({
                    value: item,
                    label: item,
                  }))}
                  className={styles.breedField}
                />
                <Form.InputText
                  name="description"
                  placeholder="Inserir nome"
                  label="Nome da raça"
                  className={styles.breedField}
                />
                <Form.InputText
                  name="abbreviation"
                  placeholder="Inserir abreviação"
                  label="Abreviação"
                />
                <Form.CheckboxButton
                  name="is_european"
                  label="Europeu?"
                  options={[
                    { label: isEuropeanOption, value: isEuropeanOption },
                  ]}
                  containerClassName={styles.temp}
                />
              </div>
            </Form>
          </div>
        </CustomModal>

        <CustomModal
          modalIsOpen={!!deleteItem}
          handleCloseModal={handleDeleteModalClose}
          modalHeader="Excluir Raça"
          modalText={`Você tem certeza que deseja excluir a raça ${deleteItem?.description}?`}
          primaryButtonLabel="EXCLUIR"
          primaryButtonAction={handleDeleteModalConfirm}
          primaryButtonType={ButtonType.destructive}
          secondaryButtonLabel="CANCELAR"
          secondaryButtonAction={handleDeleteModalClose}
        />
      </ExpandCard>
    </>
  )
}

export { BreedCard }
