import { BreedCreateRequestData, BreedProps } from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/breed/'

const getReadAllBreeds = async (): Promise<BreedProps[]> => {
  const response = await http.get(baseUrl)
  return response.data
}

const postCreateBreed = async (
  request: BreedCreateRequestData
): Promise<BreedProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const deleteBreed = async (id: number): Promise<BreedProps> => {
  const response = await http.delete(`${baseUrl}${id}/`)
  return response.data
}

const patchBreed = async (
  id: number,
  request: Partial<BreedCreateRequestData>
): Promise<BreedProps> => {
  const response = await http.patch(`${baseUrl}${id}/`, request)
  return response.data
}

export { deleteBreed, getReadAllBreeds, patchBreed, postCreateBreed }
