import { CellContext, ColumnDef } from '@tanstack/react-table'

import { AbczStatus, Tooltip } from 'components/atoms'
import { RemoveButton } from 'components/molecules'
import { cellEmptyStaticValue } from 'components/organisms/custom-table/helpers'

import {
  InseminationAnimalProps,
  InseminationTypes,
} from 'app/core/types/hormonal'
import { dateTimeFormat } from 'app/core/utils'
import { Messages } from 'config/messages'

export const baseColumns = [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: (info: CellContext<InseminationAnimalProps, unknown>): JSX.Element =>
      cellEmptyStaticValue(info.row.original.stock_number),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: (info: CellContext<InseminationAnimalProps, unknown>): JSX.Element =>
      cellEmptyStaticValue(info.row.original.electronic_eartag),
  },
]

const artificialColumnsEditable = [
  {
    header: 'Data da Inseminação',
    accessorKey: 'insemination_date',
  },
  {
    header: 'Tipo de cio',
    accessorKey: 'heat_type',
  },
  {
    header: (): JSX.Element => (
      <Tooltip
        label="Sêmen implantado"
        tooltip={Messages.ANIMAL_SEARCH_MULTIPLE_FILTERS}
      />
    ),
    accessorKey: 'semen_implanted',
  },
  {
    header: 'Inseminador responsável',
    accessorKey: 'inseminator_id',
  },
]

export const artificialColumns = [
  {
    header: 'Data da Inseminação',
    accessorKey: 'insemination_date',
    cell: (info: CellContext<InseminationAnimalProps, unknown>): string =>
      dateTimeFormat(info.row.original.insemination_date),
  },
  {
    header: 'Tipo de cio',
    accessorKey: 'heat_type',
  },
  {
    header: 'Sêmen implantado',
    accessorKey: 'semen_implanted_stock_number',
  },
  {
    header: 'Inseminador responsável',
    accessorKey: 'responsible_inseminator',
  },
]

export const naturalColumnsEditable = [
  {
    header: 'Data da Cobertura',
    accessorKey: 'insemination_date',
  },
  {
    header: (): JSX.Element => (
      <Tooltip
        label="Animal reprodutor"
        tooltip={Messages.ANIMAL_SEARCH_MULTIPLE_FILTERS}
      />
    ),
    accessorKey: 'breeding_animal_id',
  },
]

export const naturalColumns = [
  {
    header: 'Data da Cobertura',
    accessorKey: 'insemination_date',
    cell: (info: CellContext<InseminationAnimalProps, unknown>): string =>
      dateTimeFormat(info.row.original.insemination_date),
  },
  {
    header: 'Animal reprodutor',
    accessorKey: 'breeding_animal_stock_number',
  },
]

export const deleteColumn = (
  handleRemoveAnimal: (animalId: number) => void,
  handleRemoveInsemination?: (animalInseminationId: number) => void,
  inseminationAlreadyCreated?: boolean
): {
  header: string
  accessorKey: string
  cell: (info: CellContext<InseminationAnimalProps, unknown>) => JSX.Element
}[] => [
  {
    header: '',
    accessorKey: 'id',
    cell: (
      info: CellContext<InseminationAnimalProps, unknown>
    ): JSX.Element => {
      if (handleRemoveInsemination && inseminationAlreadyCreated) {
        return (
          <RemoveButton
            itemId={info.row.original.id}
            handleRemoveItem={handleRemoveInsemination}
          />
        )
      }

      return (
        <RemoveButton
          itemId={info.row.index}
          handleRemoveItem={handleRemoveAnimal}
        />
      )
    },
  },
]

export const abczStatusColumn = [
  {
    header: 'STATUS ABCZ',
    accessorKey: 'abcz_status',
    cell: (
      info: CellContext<InseminationAnimalProps, unknown>
    ): JSX.Element => {
      const abcz_status = info.row.original.abcz_status

      if (abcz_status == undefined) return <></>

      return (
        <AbczStatus
          status={abcz_status}
          message={info.row.original.abcz_status_message ?? ''}
        />
      )
    },
  },
]

const inseminationColumns = (
  handleRemoveAnimal: (animalId: number) => void,
  handleRemoveInsemination: (animalInseminationId: number) => void,
  inseminationAlreadyCreated: boolean,
  inseminationType?: InseminationTypes
): ColumnDef<InseminationAnimalProps>[] => {
  return [
    ...baseColumns,
    ...(inseminationType === InseminationTypes.natural_cover
      ? naturalColumnsEditable
      : artificialColumnsEditable),
    ...abczStatusColumn,
    ...deleteColumn(
      handleRemoveAnimal,
      handleRemoveInsemination,
      inseminationAlreadyCreated
    ),
  ]
}

export { inseminationColumns }
