import React from 'react'

import classNames from 'classnames'

import { Typography, TypographyVariant } from 'components/atoms'

import styles from './styles.module.scss'

export type LabeledCardProps = {
  title: string
}

const LabeledCard: React.FC<LabeledCardProps> = ({ title, children }) => (
  <div className={styles.labeledCardWrapper}>
    <div className={styles.labeledCardTitle}>
      <Typography text={title} variant={TypographyVariant.h5} />
    </div>
    <div className={styles.labeledCardContent}>{children}</div>
  </div>
)

const LabeledCardRow: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={classNames(styles.labeledCardRow, className)}>{children}</div>
)

const LabeledCardDateRow: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={classNames(styles.labeledCardDateRow, className)}>
    {children}
  </div>
)

const LabeledCardButtonRow: React.FC = ({ children }) => (
  <div className={styles.labeledCardButtonRow}>{children}</div>
)

const LabeledCardCol: React.FC = ({ children }) => (
  <div className={styles.labeledCardCol}>{children}</div>
)

export {
  LabeledCard,
  LabeledCardButtonRow,
  LabeledCardCol,
  LabeledCardDateRow,
  LabeledCardRow,
}
