import React, { useCallback } from 'react'

import debounce from 'debounce-promise'
import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'

import { useSchedules, useSector } from 'app/core/hooks'
import { useSectionDrawer } from 'app/core/hooks/section-drawer'
import { AnimalDrawerType } from 'app/core/types/animal'
import {
  ANIMAL_CATEGORY_FILTER,
  FEMALE_REPRODUCTIVE_STATUS_FILTER,
  FilterSections,
  REPRODUCTIVE_STATUS_FILTER,
} from 'app/core/types/filters'
import { ScheduleFilterProps } from 'app/core/types/hormonal'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

import { useForm } from '../form'
import { IFormProps } from '../form/types'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
  insemination_date_init: yup.string(),
  insemination_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { insemination_date_init } = this.parent
        return isEndDateBeforeStartDate(insemination_date_init, value)
      }
    ),
  birth_date_init: yup.string(),
  birth_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { birth_date_init } = this.parent
        return isEndDateBeforeStartDate(birth_date_init, value)
      }
    ),
  birth_forecast_start: yup.string(),
  birth_forecast_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { birth_forecast_start } = this.parent
        return isEndDateBeforeStartDate(birth_forecast_start, value)
      }
    ),
  weaning_date_init: yup.string(),
  weaning_date_end: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { weaning_date_init } = this.parent
        return isEndDateBeforeStartDate(weaning_date_init, value) || !value
      }
    ),
})

type AnimalDrawerProps = {
  includeTitle?: string
  filterTitle?: string
  isOpen: boolean
  onToggle: () => void
  onReset?: () => void
  drawerType?: AnimalDrawerType
  hasAnimalCategory?: boolean
  hasIATFScheduleDropdown?: boolean
  hasAptitudeCheckbox?: boolean
  hasWeaningDate?: boolean
  onSubmit: IFormProps['onSubmit']
  disabledMessage?: string
  disableIfEmpty?: boolean
  selectedFarmId?: number
}

const AnimalDrawer: React.FC<AnimalDrawerProps> = ({
  includeTitle = 'Incluir',
  filterTitle = 'Filtrar',
  isOpen,
  onToggle,
  onReset,
  drawerType,
  hasIATFScheduleDropdown,
  hasAptitudeCheckbox,
  hasAnimalCategory = true,
  hasWeaningDate = true,
  onSubmit,
  disableIfEmpty = false,
  disabledMessage,
  selectedFarmId,
}) => {
  const { sectorsDroplist } = useSector({ farm_id: selectedFarmId })

  const {
    schedulesDroplist,
    isLoading: isLoadingSchedule,
    loadSchedulesOptions,
  } = useSchedules({})

  const {
    handleDisabled,
    handleChange,
    resetActiveFilterSection,
    activeFiltersLength,
  } = useSectionDrawer()

  const hasActiveFilters = activeFiltersLength > 0

  const handleScheduleInputChange = debounce(
    async (value: string): Promise<SelectItemProps[]> => {
      return loadSchedulesOptions({
        schedule_name: value,
      } as ScheduleFilterProps)
    },
    500
  )

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'sector_id',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
    ],

    additionalInfoSection: [
      'female_status',
      'insemination_date_init',
      'insemination_date_end',
      'birth_date_init',
      'birth_date_end',
      'birth_forecast_start',
      'birth_forecast_end',
      'weaning_date_init',
      'weaning_date_end',
      'iatf_schedule_id',
      'aptitude',
    ],
  }

  const handleFormSubmit = useCallback(
    (
      data: Record<string, unknown>,
      event: React.BaseSyntheticEvent | undefined
    ): Promise<void> => {
      const newData = {
        ...data,
        filterType: drawerType,
      }

      return onSubmit(newData, event)
    },
    [drawerType, onSubmit]
  )

  const { Form, reset } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleReset = useCallback((): void => {
    reset()
    onReset?.call(this)
    resetActiveFilterSection()
  }, [onReset, reset, resetActiveFilterSection])

  return (
    <CustomDrawer
      title={
        drawerType == AnimalDrawerType.include ? includeTitle : filterTitle
      }
      isOpen={isOpen}
      onClose={onToggle}
      reset={handleReset}
    >
      <Form>
        <DrawerTitle text="Informações gerais" />

        <DrawerRow>
          <DrawerCol>
            <Form.InputText
              label="Nº de Brinco Eletrônico"
              placeholder="Pesquisar nº de brinco eletrônico"
              name="electronic_eartag"
              disabled={handleDisabled('electronic_eartag')}
              onChange={(event): void =>
                handleChange(
                  'electronic_eartag',
                  event.target.value,
                  filterSections?.generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <Form.InputText
              label="Nº de Nascimento"
              placeholder="Pesquisar nº de nascimento"
              name="birth_number"
              disabled={handleDisabled('birth_number')}
              onChange={(event): void =>
                handleChange(
                  'birth_number',
                  event.target.value,
                  filterSections?.generalInfoSection
                )
              }
            />
          </DrawerCol>
          <DrawerCol>
            <Form.InputText
              label="Nº de Plantel"
              placeholder="Pesquisar nº de plantel"
              name="stock_number"
              disabled={handleDisabled('stock_number')}
              onChange={(event): void =>
                handleChange(
                  'stock_number',
                  event.target.value,
                  filterSections?.generalInfoSection
                )
              }
            />
          </DrawerCol>
        </DrawerRow>

        {drawerType == AnimalDrawerType.include && (
          <>
            <DrawerRow>
              {hasAnimalCategory && (
                <DrawerCol>
                  <Form.Select
                    isClearable
                    label="Categoria"
                    name="categories"
                    options={ANIMAL_CATEGORY_FILTER}
                    disabled={handleDisabled('categories')}
                    onChange={(option): void =>
                      handleChange(
                        'categories',
                        option ? (option as SelectItemProps).value : '',
                        filterSections?.generalInfoSection
                      )
                    }
                  />
                </DrawerCol>
              )}
              {sectorsDroplist && (
                <DrawerCol>
                  <Form.Select
                    isClearable
                    label="Setor"
                    name="sector_id"
                    options={sectorsDroplist}
                    disabled={handleDisabled('sector_id')}
                    onChange={(option): void =>
                      handleChange(
                        'sector_id',
                        option ? (option as SelectItemProps).value : '',
                        filterSections?.generalInfoSection
                      )
                    }
                  />
                </DrawerCol>
              )}
              <DrawerCol>
                <Form.InputText
                  label="Nome do Animal"
                  placeholder="Pesquisar nome"
                  name="name"
                  disabled={handleDisabled('name')}
                  onChange={(event): void =>
                    handleChange(
                      'name',
                      event.target.value,
                      filterSections?.generalInfoSection
                    )
                  }
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Período Nascimento"
                  name="init_date"
                  htmlType="date"
                  disabled={handleDisabled('init_date')}
                  onChange={(event): void =>
                    handleChange(
                      'init_date',
                      event.target.value,
                      filterSections?.generalInfoSection
                    )
                  }
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="final_date"
                  htmlType="date"
                  disabled={handleDisabled('final_date')}
                  onChange={(event): void =>
                    handleChange(
                      'final_date',
                      event.target.value,
                      filterSections?.generalInfoSection
                    )
                  }
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Informações adicionais" />

            <DrawerRow>
              <DrawerCol>
                <Form.Select
                  isClearable
                  label="Status reprodutivo"
                  name="female_status"
                  options={FEMALE_REPRODUCTIVE_STATUS_FILTER}
                  disabled={handleDisabled('female_status')}
                  onChange={(option): void =>
                    handleChange(
                      'female_status',
                      option ? (option as SelectItemProps).value : '',
                      filterSections?.additionalInfoSection
                    )
                  }
                />
              </DrawerCol>
              <DrawerCol column>
                <label htmlFor="insemination_date_init">
                  Vacas inseminadas entre
                </label>
                <div>
                  <Form.InputText
                    name="insemination_date_init"
                    htmlType="date"
                    disabled={handleDisabled('insemination_date_init')}
                    onChange={(event): void =>
                      handleChange(
                        'insemination_date_init',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                  <Form.InputText
                    name="insemination_date_end"
                    htmlType="date"
                    disabled={handleDisabled('insemination_date_end')}
                    onChange={(event): void =>
                      handleChange(
                        'insemination_date_end',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                </div>
              </DrawerCol>
              <DrawerCol column>
                <label htmlFor="birth_date_init">Vacas que pariram entre</label>
                <div>
                  <Form.InputText
                    name="birth_date_init"
                    htmlType="date"
                    disabled={handleDisabled('birth_date_init')}
                    onChange={(event): void =>
                      handleChange(
                        'birth_date_init',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                  <Form.InputText
                    name="birth_date_end"
                    htmlType="date"
                    disabled={handleDisabled('birth_date_end')}
                    onChange={(event): void =>
                      handleChange(
                        'birth_date_end',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                </div>
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol column>
                <label htmlFor="birth_forecast_start">
                  Partos previstos entre
                </label>
                <div>
                  <Form.InputText
                    name="birth_forecast_start"
                    htmlType="date"
                    disabled={handleDisabled('birth_forecast_start')}
                    onChange={(event): void =>
                      handleChange(
                        'birth_forecast_start',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                  <Form.InputText
                    name="birth_forecast_end"
                    htmlType="date"
                    disabled={handleDisabled('birth_forecast_end')}
                    onChange={(event): void =>
                      handleChange(
                        'birth_forecast_end',
                        event.target.value,
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                </div>
              </DrawerCol>
              {hasWeaningDate && (
                <DrawerCol column>
                  <label htmlFor="weaning_date_init">Desmamaram entre</label>
                  <div>
                    <Form.InputText
                      name="weaning_date_init"
                      htmlType="date"
                      disabled={handleDisabled('weaning_date_init')}
                      onChange={(event): void =>
                        handleChange(
                          'weaning_date_init',
                          event.target.value,
                          filterSections?.additionalInfoSection
                        )
                      }
                    />
                    <Form.InputText
                      name="weaning_date_end"
                      htmlType="date"
                      disabled={handleDisabled('weaning_date_end')}
                      onChange={(event): void =>
                        handleChange(
                          'weaning_date_end',
                          event.target.value,
                          filterSections?.additionalInfoSection
                        )
                      }
                    />
                  </div>
                </DrawerCol>
              )}
              {hasIATFScheduleDropdown && (
                <DrawerCol>
                  <Form.Select
                    isClearable
                    isAsync
                    label="Programação IATF"
                    name="iatf_schedule_id"
                    isLoading={isLoadingSchedule}
                    defaultOptions={schedulesDroplist}
                    options={schedulesDroplist}
                    placeholder="Buscar Programação IATF"
                    disabled={handleDisabled('iatf_schedule_id')}
                    loadOptions={handleScheduleInputChange}
                    onChange={(option): void =>
                      handleChange(
                        'iatf_schedule_id',
                        option ? (option as SelectItemProps).value : '',
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                </DrawerCol>
              )}
            </DrawerRow>

            {hasAptitudeCheckbox && (
              <DrawerRow>
                <DrawerCol>
                  <Form.CheckboxButton
                    testId="aptitude-checkbox"
                    emptyLabelSpacing
                    name="aptitude"
                    options={REPRODUCTIVE_STATUS_FILTER}
                    disabled={handleDisabled('aptitude')}
                    onChange={(event): void =>
                      handleChange(
                        'aptitude',
                        event.target.checked ? 'aptitude' : '',
                        filterSections?.additionalInfoSection
                      )
                    }
                  />
                </DrawerCol>
              </DrawerRow>
            )}
          </>
        )}

        <DrawerButtonContainer>
          <Button
            tooltip={disableIfEmpty && !hasActiveFilters ? disabledMessage : ''}
            tooltipPosition="right"
            disabled={disableIfEmpty ? !hasActiveFilters : false}
            type={ButtonType.primary}
            label={
              drawerType == AnimalDrawerType.include ? 'Salvar' : 'Aplicar'
            }
            size={ButtonSize.large}
          />
        </DrawerButtonContainer>
      </Form>
    </CustomDrawer>
  )
}

export { AnimalDrawer }
