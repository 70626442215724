import React from 'react'

import classNames from 'classnames'

import { Icon, IconNames } from '../icon'
import styles from './styles.module.scss'

export type TooltipProps = {
  className?: string
  label: string
  tooltip: string
}

const Tooltip: React.FC<TooltipProps> = ({ className, label, tooltip }) => {
  return (
    <div className={classNames(styles.tooltip, className)}>
      <span>{label}</span>
      <span data-hover={tooltip} className={styles.tooltipIconContainer}>
        <Icon name={IconNames.information} className={styles.tooltipIcon} />
      </span>
    </div>
  )
}

export { Tooltip }
