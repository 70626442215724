import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useHandlings } from 'app/core/hooks/handling'
import { NavigateList } from 'app/core/routes/routes'

import { handlingListColumns } from '../tables'
import HandlingFeedbackTemplate from './template'

const HandlingFeedback: React.FC = () => {
  const history = useHistory<NavigateList>()

  const {
    savedHandling,
    failedHandlings,
    exportFailedHandlings,
    savedHandlingSector,
  } = useHandlings()

  const table = useReactTable({
    data: savedHandling?.handlings || [],
    columns: handlingListColumns({ handlingType: savedHandling?.type }),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!savedHandling || !savedHandlingSector) {
    history.push(NavigateList.animalList)
    return <></>
  }

  const handleFinishHandling = (): void => {
    history.push(`${NavigateList.handlingHistory}`)
  }

  return (
    <>
      {savedHandling && (
        <HandlingFeedbackTemplate
          handling={savedHandling}
          table={table}
          exportFailedHandlings={exportFailedHandlings}
          failedHandlings={failedHandlings}
          handleFinishHandling={handleFinishHandling}
        />
      )}
    </>
  )
}

export { HandlingFeedback }
