import { Table } from '@tanstack/react-table'
import React from 'react'

import { Icon, IconNames } from 'components/atoms'
import { CustomTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { HandledAnimalProps, HandlingEntity } from 'app/core/types/handling'

type HandlingReviewTemplateProps = {
  handling: HandlingEntity
  table: Table<HandledAnimalProps>
  handleCreateHandling: () => Promise<void>
  isLoading?: boolean
}

const HandlingReviewTemplate: React.FC<HandlingReviewTemplateProps> = ({
  handling,
  table,
  handleCreateHandling,
  isLoading,
}) => {
  return (
    <>
      <BoxedLayout
        backButton
        title={handling.type}
        date={handling.date}
        dateLabel="Data do Manejo"
        quantity={handling.handlings?.length}
        headerLargeButtonAction={handleCreateHandling}
        headerLargeButtonLabel="Salvar"
        headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
        headerLargeButtonLoading={isLoading}
      >
        <CustomTable table={table} disableNavigation isLoading={isLoading} />
      </BoxedLayout>
    </>
  )
}

export default HandlingReviewTemplate
