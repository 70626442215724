import classNames from 'classnames'

import { AbczSyncStatus } from 'app/core/types/abcz'

import styles from './styles.module.scss'

type AbczStatusProps = {
  status: AbczSyncStatus
  message: string
}

const AbczStatus: React.FC<AbczStatusProps> = ({ status, message }) => {
  const statusClassMap: Record<string, string> = {
    [AbczSyncStatus.pending]: styles.statusBgPending,
    [AbczSyncStatus.success]: styles.statusBgSuccess,
    [AbczSyncStatus.failed]: styles.statusBgFailed,
  }

  const defaultMessage = 'AGUARDANDO PROCESSAMENTO'

  return (
    <div className={styles.status}>
      <span
        className={classNames(styles.statusBg, statusClassMap[status])}
        title={message}
      >
        {status == AbczSyncStatus.pending && message == ''
          ? defaultMessage
          : message}
      </span>
    </div>
  )
}

export { AbczStatus }
