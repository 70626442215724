import { createContext, ReactNode, useContext } from 'react'

import { featureFlags } from 'config/environment/feature-flags'

type FeatureFlagContextProps = {
  abczEnabled: boolean
}

const FeatureFlagContext = createContext<FeatureFlagContextProps | undefined>(
  undefined
)

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext)

  if (!context)
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')

  return context
}
