import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreedingGroup } from 'app/core/hooks'
import { setBreedingGroup } from 'app/core/hooks/breeding/group/helpers'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalListProps, AnimalSexLabel } from 'app/core/types/animal'
import { BreedingGroupStateProps } from 'app/core/types/breeding'
import { StorageKeys } from 'app/core/types/storage'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  breedingGroupAddFemalesColumns,
  breedingGroupAddMalesColumns,
} from '../tables'
import { BreedingGroupReviewTemplate } from './template'

const BreedingGroupReview: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { animals, group } = JSON.parse(
    localStorage.getItem(StorageKeys.breedingGroup) as string
  ) as BreedingGroupStateProps

  const { addGroup, isLoading } = useBreedingGroup({})

  const [males, setMales] = useState<AnimalListProps[]>(animals.males)
  const [females, setFemales] = useState<AnimalListProps[]>(animals.females)

  const handleRemoveAnimal = (
    animalId: number,
    animalSex: AnimalSexLabel
  ): void => {
    if (animalSex === AnimalSexLabel.male)
      setMales(prevState => prevState.filter(animal => animal.id !== animalId))

    if (animalSex === AnimalSexLabel.female)
      setFemales(prevState =>
        prevState.filter(animal => animal.id !== animalId)
      )
  }

  const handleGoToFeedback = async (): Promise<void> => {
    const hasMale = males.length > 0
    const hasFemale = females.length > 0

    if (!hasMale || !hasFemale) {
      addToast({ message: Messages.BREEDING_GROUP_ANIMALS_REQUIRED })
      return
    }

    await addGroup({ group, animals })

    history.push(NavigateList.breedingGroupFeedback)
  }

  const tableFemales = useReactTable({
    data: females,
    columns: breedingGroupAddFemalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  const tableMales = useReactTable({
    data: males,
    columns: breedingGroupAddMalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    setBreedingGroup(group, males, females)
  }, [group, males, females])

  return (
    <BreedingGroupReviewTemplate
      females={females}
      tableFemales={tableFemales}
      males={males}
      tableMales={tableMales}
      group={group}
      handleGoToFeedback={handleGoToFeedback}
      isLoading={isLoading}
    />
  )
}

export { BreedingGroupReview }
