import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBreedingStation, useFarm } from 'app/core/hooks'
import {
  clearBreedingGroupStorage,
  setBreedingGroup,
} from 'app/core/hooks/breeding/group/helpers'
import { NavigateList } from 'app/core/routes/routes'
import { BreedingGroupDraftProps } from 'app/core/types/breeding'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import { dateTimeFormat } from 'app/core/utils'

import BreedingGroupCreateTemplate from './template'

const BreedingGroupCreate: React.FC = () => {
  const history = useHistory()

  const { stations, stationsDroplistOptions } = useBreedingStation({
    size: DEFAULT_ITEMS_PER_PAGE_DETAILS,
  })
  const { activeFarmsDroplist: farmsDroplist } = useFarm()

  const [stationDate, setStationDate] =
    useState<{ initDate: string; finalDate: string }>()

  const handleSelectStation = (stationId: number): void => {
    const selectedStation = stations?.items?.find(e => e.id === stationId)
    const initDate = dateTimeFormat(selectedStation?.init_date as string, true)
    const finalDate = dateTimeFormat(
      selectedStation?.final_date as string,
      true
    )
    setStationDate({ initDate, finalDate })
  }

  const handleCreateBreedingGroup = async (data: unknown): Promise<void> => {
    setBreedingGroup(data as BreedingGroupDraftProps, [], [])
    history.push(`${NavigateList.breedingGroupAddAnimals}`)
  }

  useEffect(() => {
    clearBreedingGroupStorage()
  }, [])

  return (
    <BreedingGroupCreateTemplate
      farmsDroplist={farmsDroplist}
      stationsList={stationsDroplistOptions}
      stationDate={stationDate}
      handleSelectStation={handleSelectStation}
      onSubmit={handleCreateBreedingGroup}
    />
  )
}

export { BreedingGroupCreate }
