import { Table } from '@tanstack/react-table'
import React from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import { formattedBreedingStationName } from 'app/core/hooks/breeding/group/helpers'
import { AnimalListProps, AnimalSexPlural } from 'app/core/types/animal'
import { BreedingGroupDraftProps } from 'app/core/types/breeding'
import { Messages } from 'config/messages'

type BreedingGroupReviewTemplateProps = {
  group: BreedingGroupDraftProps
  females?: AnimalListProps[]
  tableFemales: Table<AnimalListProps>
  males?: AnimalListProps[]
  tableMales: Table<AnimalListProps>
  handleGoToFeedback: () => void
  isLoading: boolean
}

const BreedingGroupReviewTemplate: React.FC<BreedingGroupReviewTemplateProps> =
  ({
    group,
    females,
    tableFemales,
    males,
    tableMales,
    handleGoToFeedback,
    isLoading,
  }) => {
    const defaultBoxedTableProps = {
      disableLastCellNavigation: true,
      noDataMessage: Messages.ANIMAL_NOT_ADDED,
    }

    return (
      <>
        {group && (
          <>
            <ReproductionLayout
              backButton
              title={group.name}
              initialDate={group.init_date}
              finalDate={group.final_date}
              breedingStationId={Number(group.breeding_station.value)}
              breedingStationName={formattedBreedingStationName(
                group.breeding_station.label
              )}
              headerLargeButtonLabel="Salvar"
              headerLargeButtonAction={handleGoToFeedback}
              headerLargeButtonIcon={
                <Icon name={IconNames['chevron-forward']} />
              }
              headerLargeButtonLoading={isLoading}
              farmId={Number(group.farm_id.value)}
              farmName={group.farm_id.label}
            >
              {females && (
                <BoxedTable
                  title={AnimalSexPlural.female}
                  table={tableFemales}
                  data={females}
                  isLoading={isLoading}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
              {males && (
                <BoxedTable
                  title={AnimalSexPlural.male}
                  table={tableMales}
                  data={males}
                  isLoading={isLoading}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
            </ReproductionLayout>
          </>
        )}
      </>
    )
  }

export { BreedingGroupReviewTemplate }
