import { debounce } from 'lodash-es'

import { IIconProps, Icon, IconNames } from 'components/atoms'

type RemoveButtonProps = {
  handleRemoveItem?: (itemId: number) => void
  itemId: number
  disabled?: boolean
  onClick?: () => void
} & IIconProps

const RemoveButton: React.FC<RemoveButtonProps> = ({
  itemId,
  disabled,
  handleRemoveItem,
  onClick,
  ...iconProps
}) => {
  const handleClick = debounce(
    () => {
      handleRemoveItem?.(itemId)
      onClick?.()
    },
    1000,
    { leading: true, trailing: false }
  )

  return (
    <Icon
      {...iconProps}
      disabled={disabled}
      name={IconNames.close}
      onClick={handleClick}
    />
  )
}

export { RemoveButton }
