import { add, differenceInDays, formatISO, parseISO } from 'date-fns'

import {
  AnimalCowhideColors,
  AnimalListProps,
  AnimalParentType,
  AnimalWithErrorMessage,
  IAnimalParentProps,
} from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const animalFatherName = (father: IAnimalParentProps): string | undefined => {
  const isBreedingGroup = father?.type === AnimalParentType.breeding_group

  const name = isBreedingGroup
    ? father?.name
    : father?.name || father?.birth_number || 'Não existe'

  return name
}

const calcAverageDailyGain = (
  birthWeight: number,
  newWeight: number,
  birthDate: string,
  handlingDate: string
): number => {
  const intervalDays = differenceInDays(
    parseISO(handlingDate),
    parseISO(birthDate)
  )

  const avg = (newWeight - birthWeight) / intervalDays
  return Math.floor(avg * 100) / 100
}

const formatWeight = (value: number | undefined): string =>
  value ? `${value} kg` : '0.00 kg'

const findAnimalId = (
  animal: AnimalListProps | undefined
): number | undefined => {
  const animalId = animal?.id

  if (!animal || !animalId) {
    addToast({ message: Messages.ANIMALS_NOT_FOUND })
    return
  }

  return animalId
}

const defaultGestationDiagnosisLimit = 280

const calcMaxBirthDate = (
  diagnosticDate: string,
  gestationDiagnosisLimit = defaultGestationDiagnosisLimit
): string | undefined => {
  const parsedLastDate = parseISO(diagnosticDate)

  const maxDate = formatISO(
    add(parsedLastDate, {
      days: gestationDiagnosisLimit,
    }),
    { representation: 'date' }
  )

  return maxDate
}

const getCowhideColor = (color: string): AnimalCowhideColors | null => {
  const colorKey = color.split('.').pop()

  if (colorKey && colorKey in AnimalCowhideColors)
    return AnimalCowhideColors[colorKey as keyof typeof AnimalCowhideColors]

  return null
}

const validateSeries = (
  value?: string,
  showErrorMessage = false
): { sanitizedValue: string; errorMessage: string } => {
  const maxSeriesLength = 4

  let sanitizedValue = value || ''
  let errorMessage = ''

  const isInvalidValue =
    sanitizedValue.length > maxSeriesLength ||
    !/^[A-Za-z]*$/.test(sanitizedValue)

  if (showErrorMessage && isInvalidValue) {
    errorMessage =
      sanitizedValue.length > maxSeriesLength
        ? Messages.MAX_4_CHARACTERS
        : Messages.ONLY_LETTERS
  }

  if (!showErrorMessage) {
    sanitizedValue = sanitizedValue
      .replace(/[^A-Za-z]/g, '')
      .slice(0, maxSeriesLength)
  }

  return { sanitizedValue, errorMessage }
}

const sortAnimalsByError = (animals: AnimalWithErrorMessage[]) => {
  return animals.sort((a, b) => {
    if (a.error_message && !b.error_message) return -1
    if (!a.error_message && b.error_message) return 1
    return 0
  })
}

const hasInvalidAnimals = (animals?: AnimalWithErrorMessage[]): boolean => {
  if (!animals) return false

  const hasInvalid = animals.some(animal => animal.error_message)

  if (hasInvalid) {
    addToast({ message: Messages.INVALID_ANIMALS })
  }

  return hasInvalid
}

export {
  animalFatherName,
  calcAverageDailyGain,
  calcMaxBirthDate,
  findAnimalId,
  formatWeight,
  getCowhideColor,
  hasInvalidAnimals,
  sortAnimalsByError,
  validateSeries,
}
