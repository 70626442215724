import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useBreedingGroup } from 'app/core/hooks'
import { clearBreedingGroupStorage } from 'app/core/hooks/breeding/group/helpers'
import { NavigateList } from 'app/core/routes/routes'
import { BreedingGroupStateProps } from 'app/core/types/breeding'
import { StorageKeys } from 'app/core/types/storage'

import {
  breedingGroupAddFemalesColumns,
  breedingGroupAddMalesColumns,
} from '../tables'
import { BreedingGroupFeedbackTemplate } from './template'

const BreedingGroupFeedback: React.FC = () => {
  const history = useHistory()

  const { animals, group } = JSON.parse(
    localStorage.getItem(StorageKeys.breedingGroup) as string
  ) as BreedingGroupStateProps

  const { failedAnimals, exportFailedAnimals } = useBreedingGroup({})

  const handleFinish = (): void => {
    clearBreedingGroupStorage()
    history.push(`${NavigateList.breedingGroupHistory}`)
  }

  const tableFemales = useReactTable({
    data: animals.females,
    columns: breedingGroupAddFemalesColumns(),
    getCoreRowModel: getCoreRowModel(),
  })

  const tableMales = useReactTable({
    data: animals.males,
    columns: breedingGroupAddMalesColumns(),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <BreedingGroupFeedbackTemplate
      females={animals.females}
      tableFemales={tableFemales}
      males={animals.males}
      tableMales={tableMales}
      group={group}
      handleFinish={handleFinish}
      failedAnimals={failedAnimals}
      exportFailedAnimals={exportFailedAnimals}
    />
  )
}

export { BreedingGroupFeedback }
