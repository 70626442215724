import * as XLSX from 'xlsx'

import {
  AnimalCategory,
  AnimalCreateProps,
  AnimalSexLabel,
  ImportAnimalProps,
} from 'app/core/types/animal'
import { BreedProps } from 'app/core/types/system'
import {
  addToast,
  dateTimeFormat,
  dateToday,
  downloadFile,
  generateXlsxTemplate,
  parseDateFromSheet,
  parseNumberFromSheet,
  parseStringFromSheet,
} from 'app/core/utils'
import { Messages } from 'config/messages'

export type ImportedAnimalsProps = {
  stock_number?: string
  birth_date?: Date
  birth_weight?: number
  current_weight?: number
  sex?: string
  category?: string
  has_pedigree?: boolean
  father?: string
  mother?: string
  is_composite_breed?: boolean
  breed?: string
  breed_percent?: string
  breed_2?: string
  breed_percent_2?: string
  fur?: string
  belongs_to_herd?: boolean

  [index: number]: string
}

const getAnimalsFromFile = async (
  content: string,
  sectorId: number,
  breeds: BreedProps[]
) => {
  const data = new Uint8Array(content as unknown as ArrayBuffer)
  const workbook = XLSX.read(data, {
    type: 'array',
    cellText: false,
    cellDates: true,
  })

  const animals: AnimalCreateProps[] = []

  const getBreed = (row: string | undefined) => {
    return row
      ? breeds.filter(
          breed => breed.description.toLowerCase() === row.toLowerCase()
        )[0]
      : undefined
  }

  const getCategory = (row: string | undefined) => {
    return row
      ? Object.entries(AnimalCategory).find(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, v]) => v.toLowerCase() === row.toLowerCase()
        )?.[1]
      : undefined
  }

  await Promise.all(
    workbook.SheetNames.map(async sheetName => {
      const worksheet = workbook.Sheets[sheetName]
      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: false,
        dateNF: 'd"/"m"/"yyyy',
        raw: true,
      }) as ImportedAnimalsProps[]

      for (let i = 1; i < rows.length; i++) {
        const row = rows[i]

        animals.push({
          sector_id: sectorId,
          stock_number: parseStringFromSheet(row[0]),
          birth_number: parseStringFromSheet(row[1]),
          name: parseStringFromSheet(row[2]),
          birth_date: parseDateFromSheet(row[3]),
          birth_weight: parseNumberFromSheet(row[4]),
          current_weight: parseNumberFromSheet(row[5]),
          sex:
            row[6].trim().toLowerCase() === 'fêmea'
              ? AnimalSexLabel.female
              : AnimalSexLabel.male,
          category: getCategory(row[7]),
          has_pedigree: row[8].trim().toLowerCase() === 'sim',
          imported_father: parseStringFromSheet(row[9]),
          imported_mother: parseStringFromSheet(row[10]),
          is_composite_breed: row[11].trim().toLowerCase() === 'sim',
          breed_first_id: getBreed(row[12])?.id,
          breed_first_name: getBreed(row[12])?.description,
          breed_first_percentage: parseStringFromSheet(row[13]),
          breed_second_id: getBreed(row[14])?.id,
          breed_second_name: getBreed(row[14])?.description,
          breed_second_percentage: parseStringFromSheet(row[15]),
          cowhide_color: parseStringFromSheet(row[16]),
          belongs_to_herd: row[17].trim().toLowerCase() === 'sim',
          belongs_to_association: row[18].trim().toLowerCase() === 'sim',
          series: parseStringFromSheet(row[19]),
        })
      }
    })
  )

  return animals
}

const useImportAnimal = async (props: ImportAnimalProps) => {
  try {
    const animals = await getAnimalsFromFile(
      props.file.filesContent[0].content,
      props.sectorId,
      props.breeds
    )

    addToast({
      message: Messages.ANIMALS_IMPORT_SUCCESSFUL,
      type: 'success',
    })

    return animals
  } catch (e) {
    addToast({ message: Messages.ERROR_MESSAGE })
  }
}

const useGetImportAnimalTemplate = async () => {
  const templateFile = generateXlsxTemplate([
    [
      'Nº de Plantel',
      'Nº de Nascimento',
      'Nome',
      'Data de Nascimento',
      'Peso de Nascimento',
      'Peso',
      'Sexo',
      `Categoria`,
      'Possui Pedigree',
      'Pai',
      'Mãe',
      'Raça composta',
      'Raça',
      'Raça porcentagem',
      'Raça 2',
      'Raça 2 porcentagem',
      'Pelagem',
      'Animal de rebanho?',
      'Animal de associação',
      'Série',
    ],
    [
      '',
      '',
      '',
      dateTimeFormat(dateToday),
      '30',
      '',
      '',
      '',
      'Não',
      '',
      '',
      'Não',
      '',
      '100',
      '',
      '',
      '',
      'Sim',
      'Não',
      '',
    ],
  ])

  downloadFile({
    data: templateFile,
    fileName: 'template-importar-animais',
  })
}

export { useGetImportAnimalTemplate, useImportAnimal }
