/* eslint-disable @typescript-eslint/naming-convention */
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { ButtonType, IconNames } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { ISelectButtonItem } from 'components/organisms'

import {
  useInsemination,
  useInseminationAnimal,
  useInseminators,
} from 'app/core/hooks'
import {
  IParamProps,
  NavigateList,
  NavigateParams,
} from 'app/core/routes/routes'
import { AnimalDrawerType, AnimalFilterProps } from 'app/core/types/animal'
import { FilterSections } from 'app/core/types/filters'
import { HeatTypes, InseminationTypes } from 'app/core/types/hormonal'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  dateTimeFormat,
  dateToday,
  downloadFile,
  generateXlsxTemplate,
  validateAndUpdateAnimals,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { inseminationColumns, inseminationColumnsEditable } from '../tables'
import { InseminationDetailsTemplate } from './template'

type InseminationDetailsLocationProps = {
  inseminationType: string
  heatType: HeatTypes
}

const InseminationDetails: React.FC = () => {
  const { inseminationId } = useParams<IParamProps>()
  const location = useLocation()
  const history = useHistory<NavigateParams>()

  const state = location.state as InseminationDetailsLocationProps

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])
  const [deleteAnimalInsemination, setDeleteAnimalInsemination] =
    useState<number>()
  const [page, setPage] = useState(1)

  const { insemination } = useInsemination({ inseminationId })
  const {
    animals,
    setAnimals,
    readAnimals,
    searchAnimals,
    removeAnimal,
    removeInsemination,
    updateAnimal,
    importInseminations,
    isLoading: isLoadingInseminationAnimal,
    isLoadingExport,
    filterAnimals,
    filterIsActive,
    exportInseminations,
  } = useInseminationAnimal({
    inseminationId,
    page,
  })
  const { inseminators, isLoading: isLoadingInseminators } = useInseminators()

  const isNaturalCover = insemination?.type === InseminationTypes.natural_cover
  const isLoading = isLoadingInseminators || isLoadingInseminationAnimal

  const inseminationAlreadyCreated =
    animals !== undefined &&
    animals.items.length > 0 &&
    animals.items.every(insemination => insemination.id !== undefined)

  const handleToggleDrawer = useCallback((): void => {
    setIsDrawerOpen(prevState => !prevState)
  }, [])

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const filterType = filters['filterType']
      delete filters['filterType']

      const updatedFilters = {
        ...filters,
      } as AnimalFilterProps

      if (filterType == AnimalDrawerType.filter) {
        filterAnimals(updatedFilters)

        return handleToggleDrawer()
      }

      if (!insemination?.breeding_station_id) return

      searchAnimals(
        updatedFilters,
        insemination?.breeding_station_id,
        insemination?.date
      )

      handleToggleDrawer()
    },
    [filterAnimals, searchAnimals, insemination, handleToggleDrawer]
  )

  const handleGoToReview = (): void => {
    const naturalCoverRequiredFields = ['breeding_animal_id']
    const inseminationRequiredFields = ['semen_implanted', 'heat_type']

    const requiredFields = [
      'insemination_date',
      'animal_id',
      ...(isNaturalCover
        ? naturalCoverRequiredFields
        : inseminationRequiredFields),
    ]

    if (animals && animals.items.length === 0) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    validateAndUpdateAnimals(
      animals?.items || [],
      requiredFields,
      updatedAnimals => {
        setAnimals(updatedAnimals)

        if (updatedAnimals.some(animal => animal.error_message)) {
          addToast({ message: Messages.INVALID_ANIMALS })
          return
        }

        localStorage.setItem(
          StorageKeys.inseminations_review,
          JSON.stringify(updatedAnimals)
        )

        history.push(`${NavigateList.inseminationReview}${inseminationId}`)
      }
    )
  }

  const table = useReactTable({
    data: animals?.items || [],
    columns: useMemo(
      () =>
        inseminationColumns(
          removeAnimal,
          setDeleteAnimalInsemination,
          inseminationAlreadyCreated,
          insemination?.type
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        updateAnimal,
        insemination?.type,
        inseminationAlreadyCreated,
        removeAnimal,
        setDeleteAnimalInsemination,
      ]
    ),
    defaultColumn: useMemo(
      () =>
        inseminationColumnsEditable(
          updateAnimal,
          insemination?.breeding_station_id,
          state?.heatType,
          inseminators?.items
        ),
      [
        insemination?.breeding_station_id,
        inseminators?.items,
        state?.heatType,
        updateAnimal,
      ]
    ),
    getCoreRowModel: getCoreRowModel(),
  })

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'sector_id',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
    ],

    additionalInfoSection: [
      'female_status',
      'insemination_date_init',
      'insemination_date_end',
      'birth_date_init',
      'birth_date_end',
      'birth_forecast_start',
      'birth_forecast_end',
      'weaning_date_init',
      'weaning_date_end',
      'iatf_schedule_id',
      'aptitude',
    ],
  }

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = useCallback(
    (section: string[] | undefined): void => {
      if (section) {
        setActiveFilterSection(section)
      }
    },
    [setActiveFilterSection]
  )

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    await importInseminations(selectedFile, insemination?.breeding_station_id)
  }

  const inseminationTemplate = [
    'Data da inseminação',
    'Tipo de cio',
    'Sêmen implantado (número de plantel)',
    'Inseminador responsável',
  ]

  const naturalCoverTemplate = [
    'Data da cobertura',
    'Animal reprodutor (número de plantel)',
  ]

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nº de Plantel',
            'Nº de Brinco eletrônico',
            'Nº de Nascimento',
            ...(isNaturalCover ? naturalCoverTemplate : inseminationTemplate),
          ],
          [
            '',
            '',
            '',
            dateTimeFormat(dateToday),
            !isNaturalCover ? 'Protocolo Hormonal ou Cio Natural' : '',
            '',
            !isNaturalCover ? 'ID ou Nome completo' : '',
          ],
        ])

        downloadFile({
          data: templateFile,
          fileName: `template-${isNaturalCover ? 'cobertura' : 'inseminacao'}`,
        })
      },
    },
  ]

  const alreadyCreatedSelectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        exportInseminations()
      },
    },
  ]

  const handleDeleteModalClose = (): void => {
    setDeleteAnimalInsemination(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteAnimalInsemination) {
      await removeInsemination(deleteAnimalInsemination)

      handleDeleteModalClose()
    }
  }, [deleteAnimalInsemination, removeInsemination])

  const handleResetFilters = useCallback(async (): Promise<void> => {
    filterAnimals({} as AnimalFilterProps)
  }, [filterAnimals])

  useEffect(() => {
    readAnimals()
  }, [readAnimals])

  return (
    <>
      <InseminationDetailsTemplate
        insemination={insemination}
        animals={animals}
        isDrawerOpen={isDrawerOpen}
        isLoading={isLoading}
        isLoadingExport={isLoadingExport}
        handleToggleDrawer={handleToggleDrawer}
        onSubmit={handleFilterAnimal}
        table={table}
        page={page}
        setPage={setPage}
        setActiveFilterSection={setActiveFilterSection}
        handleChange={handleChange}
        handleDisabled={handleDisabled}
        filterSections={filterSections}
        showActionButton={!inseminationAlreadyCreated}
        handleGoToReview={handleGoToReview}
        alreadyCreatedHeaderSelectButtonItems={alreadyCreatedSelectButtonItems}
        headerSelectButtonItems={selectButtonItems}
        resetFilters={handleResetFilters}
        filterIsActive={filterIsActive}
      />

      <CustomModal
        modalIsOpen={!!deleteAnimalInsemination}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Registro`}
        modalText={`Você tem certeza que deseja excluir este registro?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { InseminationDetails }
