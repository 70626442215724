import { SectorFilterProps } from 'app/core/types/hooks'
import { SectorCreateRequestData, SectorProps } from 'app/core/types/system'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/sector/'

const getReadAllSectors = async (
  filters?: Partial<SectorFilterProps>
): Promise<SectorProps[]> => {
  const response = await http.get(baseUrl, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postCreateSector = async (
  request: SectorCreateRequestData
): Promise<SectorProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchEditSector = async (
  sectorId: number,
  request: SectorCreateRequestData
): Promise<SectorProps> => {
  const response = await http.patch(`${baseUrl}${sectorId}/`, request)
  return response.data
}

export { getReadAllSectors, patchEditSector, postCreateSector }
