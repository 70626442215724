/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'

import { getReadAllAnimals } from 'app/core/services'
import {
  deleteCalfBirth,
  getBirthInfo,
  getReadAllBirths,
  getReadBirth,
  getReadBirthsStream,
  patchUpdateBirth,
  postCreateBirth,
} from 'app/core/services/births'
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  ABORTION_TYPES,
  BirthAnimalReadResponseProps,
  BirthCalfFatherError,
  BirthCreateHookProps,
  BirthCreateProps,
  BirthHook,
  BirthHookProps,
  BirthProps,
  BirthsResponseData,
} from 'app/core/types/birth'
import { StorageKeys } from 'app/core/types/storage'
import { DEFAULT_ITEMS_PER_PAGE_LIST } from 'app/core/types/system'
import {
  arrayToCsv,
  dateForFileName,
  dateTimeISOFormat,
  downloadFile,
  generateXlsxTemplate,
  getAnimalSex,
  getCowhideColor,
  getFormattedDateValueOrNull,
  getPlural,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'
import { addToast } from 'app/core/utils/toast'
import { Messages } from 'config/messages'

const useBirth = ({
  birthId,
  filters,
  page,
  size,
}: BirthHookProps): BirthHook => {
  const savedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.birth_review) as string
  ) as BirthCreateProps[]
  const savedFailedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.birth_review_failed) as string
  ) as BirthCreateProps[]

  const [animals, setAnimals] = useState<BirthCreateProps[]>(
    () => savedAnimals || []
  )
  const [failedAnimals, setFailedAnimals] = useState<BirthCreateProps[]>(
    () => savedFailedAnimals || []
  )
  const [births, setBirths] = useState<BirthsResponseData>()
  const [birth, setBirth] = useState<BirthProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllBirths = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllBirths(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      if (data.total === 0) {
        addToast({
          message: Messages.BIRTHS_NOT_FOUND,
          type: 'warning',
        })
      }

      setBirths(data)
      setIsLoading(false)
    } catch (e) {
      handleHttpError(e)
    }
  }, [filters, page, size])

  const readBirth = useCallback(async (): Promise<void> => {
    if (birthId) {
      try {
        const data = await getReadBirth(birthId)
        setBirth(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [birthId])

  const updateAnimals = useCallback(
    async (index: number, payload: BirthCreateProps): Promise<void> => {
      setAnimals(prevState => {
        const animals = [...prevState]
        const updatedAnimal = { ...animals[index], ...payload }
        animals[index] = updatedAnimal

        return animals
      })
    },
    []
  )

  const addBirth = useCallback(async ({ animals }: BirthCreateHookProps) => {
    setIsLoading(true)

    const calfsGroupedByMother = animals.reduce((acc, birth) => {
      const { mother_id } = birth
      if (!mother_id) return acc

      if (!acc[mother_id]) {
        acc[mother_id] = []
      }

      acc[mother_id].push(birth)
      return acc
    }, {} as Record<string | number, BirthCreateProps[]>)

    const births = Object.entries(calfsGroupedByMother).map(
      ([mother_id, births]) => {
        const firstBirth = births[0]
        const { date, type } = firstBirth

        const calf_birth = {
          date: date ? dateTimeISOFormat(date) : date,
          type,
          mother_id,
        }

        const calfs = births
          .map(
            ({
              birth_number,
              name,
              sex,
              cowhide_color,
              birth_weight,
              mother_belongs_to_association,
              series,
            }) => {
              if (type && ABORTION_TYPES.includes(type)) {
                return {}
              }

              return {
                birth_number,
                name,
                sex,
                cowhide_color,
                birth_weight,
                ...(mother_belongs_to_association && { series }),
              }
            }
          )
          .filter(calf => Object.keys(calf).length > 0)

        return {
          calf_birth,
          ...(calfs.length > 0 && { new_calf: calfs }),
        }
      }
    )

    try {
      let successfulAnimals = animals
      const failedAnimals = [] as BirthCreateProps[]

      const createBirths = births.map(async birth => {
        try {
          await postCreateBirth(birth)
          return 'fulfilled'
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const motherId = birth.calf_birth.mother_id
            let errorMessage = error.response?.data['message']
            const failedAnimal =
              calfsGroupedByMother[motherId as string | number]

            if (error.response?.status === 422) {
              const errorDetail = error.response?.data['detail'][0]

              const { type, loc } = errorDetail
              const field = loc[loc.length - 1]

              if (type === 'decimal_whole_digits' && field === 'birth_weight') {
                errorMessage =
                  'Campo "Peso de nascimento" deve ter no máximo 5 dígitos'
              }
            }

            failedAnimals.push({
              ...failedAnimal[0],
              error_message: errorMessage,
            })

            successfulAnimals = [
              ...successfulAnimals.filter(
                animal => animal.mother_id?.toString() !== motherId
              ),
            ]
          }

          return 'rejected'
        }
      })

      await Promise.all(createBirths)

      setFailedAnimals(failedAnimals)
      setAnimals(successfulAnimals)

      localStorage.setItem(
        StorageKeys.birth_review,
        JSON.stringify(successfulAnimals)
      )
      localStorage.setItem(
        StorageKeys.birth_review_failed,
        JSON.stringify(failedAnimals)
      )

      if (failedAnimals.length > 0) {
        addToast({
          message: `Não foi possível criar ${getPlural(
            'parto',
            failedAnimals.length,
            true
          )}. Verifique a planilha de inconsistências e tente novamente.`,
        })
      }

      if (successfulAnimals.length > 0) {
        addToast({
          message: `${getPlural(
            'Parto',
            successfulAnimals.length,
            true
          )} ${getPlural('criado', successfulAnimals.length)} com sucesso.`,
          type: 'success',
        })
      }
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const addAnimal = useCallback(
    async (filters: AnimalFilterProps): Promise<void> => {
      try {
        const animals = (await getReadAllAnimals(
          filters
        )) as BirthAnimalReadResponseProps

        if (animals.total === 0) {
          addToast({ message: Messages.ANIMALS_NOT_FOUND })

          return
        }

        const birthInfo = animals.items.map(async animal => {
          try {
            const birth = await getBirthInfo(animal.id)

            const { last_diagnostic_date, insemination_date, father_name, calf_breeds, calf_is_composite_breed } =
              birth

            animal.calf_father = father_name
            ;(animal.last_diagnostic_date = last_diagnostic_date),
              (animal.insemination_date = insemination_date)
            animal.is_birth_valid = true
            animal.calf_is_composite_breed = calf_is_composite_breed
            animal.calf_breeds = calf_breeds


            return 'fulfilled'
          } catch (error) {
            animal.is_birth_valid = false

            return {
              animal_id: animal.id,
              error,
            }
          }
        })

        const requests = await Promise.all(birthInfo)

        requests.forEach(result => {
          if (result !== 'fulfilled') {
            const error = result as BirthCalfFatherError

            addToast({
              message: `${error.error.response?.data.message}: ${error.animal_id}`,
            })
          }
        })

        const formattedAnimals: BirthCreateProps[] = animals.items
          .filter(animal => animal.is_birth_valid)
          .map(mother => ({
            mother_id: mother.id,
            mother_electronic_eartag: mother.electronic_eartag,
            mother_stock_number: mother.stock_number,
            mother_belongs_to_association: mother.belongs_to_association,
            father_name: mother.calf_father,
            last_diagnostic_date: mother.last_diagnostic_date,
            insemination_date: mother.insemination_date,
            calf_is_composite_breed: mother.calf_is_composite_breed,
            calf_breeds: mother.calf_breeds,
          }))

        setAnimals(prevAnimals => [...prevAnimals, ...formattedAnimals])
      } catch (e) {
        handleHttpError(e)
      }
    },
    []
  )

  const updateBirth = useCallback(
    async (data: BirthProps) => {
      if (birthId) {
        try {
          const { calf_birth_number, calf_name, calf_sex, calf_cowhide_color } =
            data

          const payload = {
            ...(data.birth_date && {
              date: dateTimeISOFormat(data.birth_date),
            }),
            ...(data.calf_birth_weight && {
              calf_birth_weight: Number(data.calf_birth_weight),
            }),
            ...(calf_birth_number && { calf_birth_number }),
            ...(calf_name && { calf_name }),
            ...(calf_sex && { calf_sex }),
            ...(calf_cowhide_color && { calf_cowhide_color }),
          }

          await patchUpdateBirth(birthId, payload)

          addToast({
            message: Messages.BIRTHS_UPDATE_SUCCESS,
            type: 'success',
          })

          const newBirth = {
            ...birth,
            ...payload,
          } as BirthProps

          setBirth(newBirth)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [birth, birthId]
  )

  const deleteBirth = useCallback(
    async (birthId: number): Promise<void> => {
      try {
        await deleteCalfBirth(birthId)

        const newBirths = { ...births }

        newBirths.items = newBirths?.items?.filter(birth => birth.id != birthId)
        newBirths.total = (newBirths.total ?? 1) - 1

        setBirths(newBirths as unknown as BirthsResponseData)

        addToast({
          message: Messages.BIRTHS_DELETED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [births]
  )

  const exportBirths = useCallback(async () => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getReadBirthsStream(filters, page)

      const births = data.map(birth => ({
        'Nº de nascimento': getValueOrNull(birth.calfBirthNumber),
        Nome: getValueOrNull(birth.calfName),
        Sexo: getAnimalSex(birth.calfSex),
        Pelagem: birth.calfCowhideColor
          ? getCowhideColor(birth.calfCowhideColor)
          : null,
        'Peso de nascimento': birth.calfBirthWeight
          ? getValueOrNull(
              numberWithThousandDotSeparatorFormat(birth.calfBirthWeight)
            )
          : null,
        Raça: getValueOrNull(birth.calfBreed),
        Espécie: getValueOrNull(birth.calfSpecie),
        'Nome do pai': getValueOrNull(birth.calfFather),
        'Nº de plantel da mãe': getValueOrNull(birth.motherStockNumber),
        'Brinco eletrônico da mãe': getValueOrNull(
          birth.motherElectronicEartag
        ),
        'Fazenda Atual': getValueOrNull(birth.farm),
        'Setor Atual': getValueOrNull(birth.sector),
        'Data do parto': getFormattedDateValueOrNull(birth.birthDate),
        'Tipo de parto': getValueOrNull(birth.birthType),
        Série: getValueOrNull(birth.calfSeries),
        'Associação de Raça': getValueOrNull(birth.breedAssociation),
        'Regime Alimentar': getValueOrNull(birth.breedAssociationFeedingGroup),
        'Grupo de Manejo': getValueOrNull(birth.breedAssociationHandlingGroup),
      }))

      const blob = arrayToCsv(births, ';')

      downloadFile({
        data: blob,
        fileName: `partos-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({ message: Messages.BIRTHS_EXPORT_SUCCESS, type: 'success' })
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters, page])

  const exportFailedAnimals = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel da mãe',
        'Nº de Brinco eletrônico da mãe',
        'Nº de Nascimento da cria',
        'Inconsistência',
      ],
      ...failedAnimals.map(animal => [
        animal.mother_stock_number,
        animal.mother_electronic_eartag,
        animal.birth_number,
        animal.error_message,
      ]),
    ])

    downloadFile({
      data: templateFile,
      fileName: `partos-${dateForFileName()}`,
    })
  }

  const removeStorage = (): void => {
    localStorage.removeItem(StorageKeys.birth_review)
    localStorage.removeItem(StorageKeys.birth_review_failed)
  }

  useEffect(() => {
    readAllBirths()
  }, [readAllBirths])

  useEffect(() => {
    if (birthId && !birth) {
      readBirth()
    }
  }, [birth, birthId, readBirth])

  return {
    animals,
    updateAnimals,
    births,
    birth,
    addBirth,
    addAnimal,
    updateBirth,
    setAnimals,
    failedAnimals,
    exportBirths,
    exportFailedAnimals,
    removeStorage,
    isLoading,
    isLoadingExport,
    deleteBirth,
  }
}

export { useBirth }
