/* eslint-disable @typescript-eslint/naming-convention */
import { CellContext, ColumnDef } from '@tanstack/react-table'

import { Chip } from 'components/atoms'
import { RemoveButton } from 'components/molecules'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { AnimalListProps, AnimalSexLabel } from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

const breedingGroupAddFemalesColumns = (
  handleRemoveAnimal?: (id: number, sex: AnimalSexLabel) => void
): ColumnDef<AnimalListProps>[] => [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nascimento',
    accessorKey: 'birth_date',
    cell: (info: CellContext<AnimalListProps, unknown>): JSX.Element => {
      const { birth_date } = info.row.original
      return (
        <span>
          {birth_date ? dateTimeFormat(info.row.original.birth_date) : '-'}
        </span>
      )
    },
  },
  {
    header: 'Último Parto',
    accessorKey: 'last_calving',
    cell: (info: CellContext<AnimalListProps, unknown>): JSX.Element => {
      const { last_calving } = info.row.original
      return (
        <span>
          {last_calving ? dateTimeFormat(info.row.original.last_calving) : '-'}
        </span>
      )
    },
  },
  {
    header: 'Status Reprodutivo',
    accessorKey: 'reproductive_status',
    cell: (info: CellContext<AnimalListProps, unknown>): JSX.Element => (
      <Chip label={info.row.original.reproductive_status} selected={false} />
    ),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <>
        {handleRemoveAnimal && (
          <RemoveButton
            itemId={info.row.original.id}
            onClick={(): void =>
              handleRemoveAnimal(
                info.row.original.id,
                info.row.original.sex as AnimalSexLabel
              )
            }
          />
        )}
      </>
    ),
  },
]

export { breedingGroupAddFemalesColumns }
