import { Chip } from 'components/atoms'

import { BreedProps } from 'app/core/types/system'
import { IAnimalBreed } from 'app/core/types/animal'

type BreedChipProps = {
    breeds: BreedProps[] | IAnimalBreed[]
    isCompositeBreed: boolean
}

export const BreedChip: React.FC<BreedChipProps> = ({
    breeds,
    isCompositeBreed
}) => {
  const label = () => {
    if (isCompositeBreed) {
      return 'Composto'
    }

    if (breeds && breeds.length === 1) {
      return breeds[0].description
    }

    return 'Misto'
  }

  return <Chip label={label()} selected={false} />
}
