import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { NavigateList } from 'app/core/routes/routes'
import {
  getGeneralReport,
  getReadAllAnimals,
  getReadAnimal,
  getReadAnimalsAgeGroupReport,
  getReadAnimalsStream,
  patchUpdateAnimal,
  postCreateAnimalBulk,
} from 'app/core/services'
import {
  AgeGroupReport,
  AgeGroupReportProps,
  AgeGroupReportSector,
} from 'app/core/types/age-group'
import {
  AnimalCreateResponseData,
  AnimalFilterProps,
  AnimalReadResponseProps,
  AnimalUpdateRequestData,
  ExportHerdDynamicRequestData,
  IAnimalEntity,
} from 'app/core/types/animal'
import { AnimalEvaluations, EvaluationType } from 'app/core/types/evaluation'
import {
  AnimalCreateHookProps,
  AnimalHook,
  AnimalHookProps,
} from 'app/core/types/hooks'
import { StorageKeys } from 'app/core/types/storage'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  dateTimeFormat,
  dateTimeISOFormat,
  downloadFile,
  getAnimalSex,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  displayErrorMessage,
  generateHerdDynamicSheet,
  getFailedAnimals,
  getLastBirthSexOrNull,
  reviewKeyManagement,
} from './helper'

const useAnimal = ({
  animalId,
  filters,
  page,
}: AnimalHookProps): AnimalHook => {
  const history = useHistory<NavigateList>()

  const [animals, setAnimals] = useState<AnimalReadResponseProps>()
  const [animal, setAnimal] = useState<IAnimalEntity>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllAnimals = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      const data = await getReadAllAnimals(
        filters,
        page,
        DEFAULT_ITEMS_PER_PAGE_DETAILS
      )
      setAnimals(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page])

  const searchAnimals = useCallback(
    async (
      filters?: Partial<AnimalFilterProps>
    ): Promise<AnimalReadResponseProps> => {
      try {
        const data = await getReadAllAnimals(
          filters,
          1,
          DEFAULT_ITEMS_PER_PAGE_DETAILS
        )

        return data
      } catch (e) {
        handleHttpError(e)
        return Promise.reject(e)
      }
    },
    []
  )

  const addAnimal = async (props: AnimalCreateHookProps): Promise<void> => {
    const { animals, sector, setAnimals, route } = props

    const savedRecentlyAddedAnimals = localStorage.getItem(
      StorageKeys.animals_feedback_positive
    )
    const recentlyAddedAnimals = savedRecentlyAddedAnimals
      ? JSON.parse(savedRecentlyAddedAnimals)
      : []

    const animalsData = animals.map(animal => ({
      ...animal,
      birth_date: animal.birth_date
        ? dateTimeISOFormat(animal.birth_date)
        : animal.birth_date,
    }))

    try {
      const response = (await postCreateAnimalBulk(
        animalsData
      )) as AnimalCreateResponseData

      const createdAnimals =
        response.created_animals?.map(animal => ({
          ...animal,
          birth_date: animal.birth_date
            ? dateTimeFormat(animal.birth_date, true)
            : animal.birth_date,
        })) || []

      const positiveFeedback = recentlyAddedAnimals.length
        ? createdAnimals.concat(recentlyAddedAnimals)
        : createdAnimals

      localStorage.setItem(
        StorageKeys.animals_feedback_positive,
        JSON.stringify(positiveFeedback)
      )

      if (!response.failed_animals?.length) {
        reviewKeyManagement(positiveFeedback, sector)

        history.push(route)

        return
      } else {
        const failedAnimals = getFailedAnimals(response.failed_animals)

        displayErrorMessage(failedAnimals.length, positiveFeedback.length)

        const newReview = failedAnimals.concat(createdAnimals)

        localStorage.setItem(
          StorageKeys.animals_review,
          JSON.stringify(newReview)
        )
        setAnimals(newReview)
      }
    } catch (e) {
      handleHttpError(e, false)
    }
  }

  const readAnimal = useCallback(async () => {
    if (animalId) {
      try {
        const animal = await getReadAnimal(animalId)
        setAnimal(animal)
      } catch (e) {
        addToast({ message: (e as Error).message })
      }
    }
  }, [animalId])

  const exportAnimals = useCallback(async () => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getReadAnimalsStream(filters)

      const animals = data.map(animal => ({
        'Nº de nascimento': getValueOrNull(animal.birthNumber),
        'Nº de plantel': getValueOrNull(animal.stockNumber),
        'Brinco eletrônico': String(animal.electronicEartag ?? ''),
        Nome: getValueOrNull(animal.name),
        'Dt. nasc.': getFormattedDateValueOrNull(animal.birthDate),
        'Sx.': getAnimalSex(animal.sex),
        'Nº Pai': getValueOrNull(animal.father),
        'Nº Mãe': getValueOrNull(animal.mother),
        Raça: getValueOrNull(animal.breedUnified),
        Categoria: getValueOrNull(animal.category),
        Setor: getValueOrNull(animal.sector),
        Fazenda: getValueOrNull(animal.farm),
        'Dt. desmama': getFormattedDateValueOrNull(animal.weaningDate),
        'PDes (Kg)': getValueOrNull(animal.weaningWeight),
        'Dt. próx. parto': getFormattedDateValueOrNull(animal.birthForecast),
        'Nº da Cria do último parto': getValueOrNull(
          animal.lastGivenBirthStockNumber || animal.lastGivenBirthBirthNumber
        ),
        'Sx. Últ. Parto': getLastBirthSexOrNull(animal.lastGivenBirthSex),
        'Dt. Últ. Parto': getFormattedDateValueOrNull(
          animal.lastGivenBirthDate
        ),
        'Tp. Últ. Parto': getValueOrNull(animal.lastGivenBirthType),
        'Data da Baixa': getFormattedDateValueOrNull(animal.shutdownDate),
        'Motivo da Baixa': getValueOrNull(animal.shutdownType),
        'Causa de Baixa': getValueOrNull(animal.shutdownReason),
        Série: getValueOrNull(animal.series),
        'Associação de Raça': getValueOrNull(animal.breedAssociation),
        'Regime Alimentar': getValueOrNull(animal.breedAssociationFeedingGroup),
        'Grupo de Manejo': getValueOrNull(animal.breedAssociationHandlingGroup),
      }))

      const blob = arrayToCsv(animals, ';')

      downloadFile({
        data: blob,
        fileName: `animais-totais-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  const exportGeneralReport = useCallback(async () => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getGeneralReport(filters)

      const animals = data.map(animal => ({
        'Nº de nascimento': getValueOrNull(animal.birthNumber),
        'Nº de plantel': getValueOrNull(animal.stockNumber),
        'Brinco eletrônico': String(animal.electronicEartag ?? ''),
        Nome: getValueOrNull(animal.name),
        Série: getValueOrNull(animal.series),
        'Associação de Raça': getValueOrNull(animal.breedAssociation),
        'Grupo de Manejo': getValueOrNull(animal.breedAssociationHandlingGroup),
        'Regime Alimentar': getValueOrNull(animal.breedAssociationFeedingGroup),
        'Dt. Últ. Observação': getFormattedDateValueOrNull(
          animal.observationDate
        ),
        Observação: getValueOrNull(animal.observation),
        'Dt. nasc.': getFormattedDateValueOrNull(animal.birthDate),
        'Grupo de Idade': getValueOrNull(animal.ageGroup),
        'Sx.': getAnimalSex(animal.sex),
        'Nº Pai': getValueOrNull(animal.father),
        'Nº Mãe': getValueOrNull(animal.mother),
        Raça: getValueOrNull(animal.breedUnified),
        Categoria: getValueOrNull(animal.category),
        Setor: getValueOrNull(animal.sector),
        Fazenda: getValueOrNull(animal.farm),
        'Dt. desmama': getFormattedDateValueOrNull(animal.weaningDate),
        'PDes (Kg)': getValueOrNull(animal.weaningWeight),
        'Dt. Últ. Peso': getFormattedDateValueOrNull(animal.lastWeightDate),
        'Últ. Peso (Kg)': getValueOrNull(animal.lastWeight),
        'GMD Vida': getValueOrNull(animal.lifeADG),
        'Dt. Últ. Avaliação': getFormattedDateValueOrNull(
          animal.lastEvaluationDate
        ),
        'Últ. Avaliação': animal.lastEvaluationScore
          ? AnimalEvaluations[
              animal.lastEvaluationScore as unknown as EvaluationType
            ]
          : null,
        'Obs. Últ. Avaliação': getValueOrNull(animal.lastEvaluation),
        'Dt. Últ. Recomendação Acasalamento': getFormattedDateValueOrNull(
          animal.lastRecommendationDate
        ),
        'Macho Recomendado': getValueOrNull(animal.lastRecommendationMale),
        'Dt. Últ. Inseminação': getFormattedDateValueOrNull(
          animal.lastInseminationDate
        ),
        'Tipo Cio': getValueOrNull(animal.lastInseminationType),
        'Semen Implantado': getValueOrNull(animal.lastInseminationSemen),
        'Inseminador Responsável': getValueOrNull(
          animal.lastInseminationResponsible
        ),
        'Dt. Últ. Diag.': getFormattedDateValueOrNull(
          animal.lastReproductiveDiagnosticDate
        ),
        'Últ. Diag.': getValueOrNull(animal.lastReproductiveDiagnostic),
        'Dt. próx. parto': getFormattedDateValueOrNull(animal.birthForecast),
        'Dt. Últ. Parto': getFormattedDateValueOrNull(
          animal.lastGivenBirthDate
        ),
        'Tp. Últ. Parto': getValueOrNull(animal.lastGivenBirthType),
        'Nº da Cria do último parto': getValueOrNull(
          animal.lastGivenBirthStockNumber || animal.lastGivenBirthBirthNumber
        ),
        'Sx. Últ. Parto': getLastBirthSexOrNull(animal.lastGivenBirthSex),
        'Data da Baixa': getFormattedDateValueOrNull(animal.shutdownDate),
        'Motivo da Baixa': getValueOrNull(animal.shutdownType),
        'Causa de Baixa': getValueOrNull(animal.shutdownReason),
      }))

      const blob = arrayToCsv(animals, ';')

      downloadFile({
        data: blob,
        fileName: `relatorio-geral-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  const exportHerdDynamic = useCallback(
    async (request: ExportHerdDynamicRequestData) => {
      try {
        const { startDate, endDate } = request

        addToast({
          message: Messages.GENERATING_EXPORT_FILE,
          type: 'info',
        })

        const response = await getReadAnimalsAgeGroupReport(startDate, endDate)

        const data: string[][] = []

        const total: Partial<AgeGroupReport> = {}

        const propertiesToSum = [
          'total_start',
          'births',
          'addition',
          'age_group_in',
          'sector_in',
          'deaths',
          'sales',
          'others',
          'age_group_out',
          'sector_out',
          'total_finish',
        ]

        Object.values(response).forEach((farm: AgeGroupReportProps) => {
          for (const sectorId in farm?.sectors) {
            const sector = farm.sectors[
              sectorId as keyof typeof farm.sectors
            ] as unknown as AgeGroupReportSector

            for (const groupName in sector.age_groups) {
              const ageGroup: string[] = []
              const group = sector.age_groups[groupName]

              ageGroup.push(farm.name as unknown as string)
              ageGroup.push(sector.name)
              ageGroup.push(group.name)

              propertiesToSum.forEach(prop => {
                const value = group[prop] || 0
                ageGroup.push(String(value))
                total[prop] = Number(total[prop] || 0) + Number(value)
              })

              data.push(ageGroup)
            }
          }
        })

        data.push([
          'Totais',
          '',
          '',
          ...Object.values(total).map(value => String(value)),
        ])

        generateHerdDynamicSheet(startDate, endDate, data)

        addToast({
          message: Messages.ANIMAL_EXPORTED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }
    },
    []
  )

  const updateAnimal = useCallback(
    async (request: Partial<AnimalUpdateRequestData>) => {
      if (animalId) {
        try {
          await patchUpdateAnimal(animalId, request)

          addToast({
            message: Messages.ANIMAL_UPDATE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [animalId]
  )

  useEffect(() => {
    if (!animalId) {
      readAllAnimals()
    }
  }, [animalId, readAllAnimals])

  useEffect(() => {
    if (animalId) {
      readAnimal()
    }
  }, [animalId, readAnimal])

  return {
    animals,
    animal,
    addAnimal,
    exportAnimals,
    exportGeneralReport,
    exportHerdDynamic,
    updateAnimal,
    searchAnimals,
    isLoadingExport,
    isLoading,
  }
}

export { useAnimal }
