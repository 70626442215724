import { isAfter, parse } from 'date-fns'

import { SelectItemProps } from 'components/atoms'

import { Messages } from 'config/messages'

const ErrorDictionary = {
  ID: Messages.ANIMAL_NOT_FOUND,
  ANIMAL_ID: Messages.ANIMAL_NOT_FOUND,
  BIRTH_DATE: 'Data de nascimento',
  BIRTH_NUMBER: 'Nº de nascimento',
  BIRTH_WEIGHT: 'Peso de nascimento',
  BREED_FIRST_NAME: 'Raça 1',
  BREED_FIRST_PERCENTAGE: 'Percentual da raça 1',
  BREED_SECOND_NAME: 'Raça 2',
  BREED_SECOND_PERCENTAGE: 'Percentual da raça 2',
  BREEDING_ANIMAL_ID: 'Animal reprodutor',
  COWHIDE_COLOR: 'Pelagem',
  CURRENT_WEIGHT: 'Peso de entrada',
  DATE: 'Data',
  DIAGNOSTIC_DATE: 'Data do diagnóstico',
  GESTATION_DAYS: 'Dias de gestação',
  HEAT_TYPE: 'Tipo de cio',
  INSEMINATION_DATE: 'Data de inseminação',
  NAME: 'Nome',
  NEW_ELECTRONIC_EARTAG: 'Novo Nº de brinco eletrônico',
  NEW_STOCK_NUMBER: 'Novo Nº de plantel',
  PRODUCT: 'Produto/Quantidade',
  SEMEN_IMPLANTED: 'Sêmen implantado',
  SERIES: 'Série',
  SEX: 'Sexo',
  STATUS: 'Status',
  STOCK_NUMBER: 'Nº de plantel',
  TYPE: 'Tipo',
  WEIGHT: 'Peso',
} as const

const clearEmptyFields = (
  values: Record<string, unknown>
): Record<string, unknown> => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (['', null, undefined].includes(value as string)) {
      return acc
    }
    return { ...acc, [key]: value }
  }, {})
}

const isEndDateBeforeStartDate = (
  startDate: string | undefined,
  endDate: string | undefined
): boolean => {
  if (
    startDate === '' ||
    startDate === undefined ||
    endDate === '' ||
    endDate === undefined
  ) {
    return true
  }

  const inputDateFormat = 'yyyy-MM-dd'

  const currentStartDate = parse(startDate || '', inputDateFormat, new Date())
  const currentEndDate = parse(endDate || '', inputDateFormat, new Date())

  if (isNaN(currentStartDate.getTime()) || isNaN(currentEndDate.getTime())) {
    return false
  }

  if (isAfter(currentStartDate, currentEndDate)) {
    return false
  }

  return true
}

const isSelectItem = (value: unknown): value is SelectItemProps => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'label' in value &&
    'value' in value &&
    typeof (value as SelectItemProps).value === 'string'
  )
}

const findInvalidFields = <T extends Record<string, unknown>>(
  animals: T[],
  requiredFields: string[],
  additionalValidation?: (animal: T, invalidFields: string[]) => void
): { index: number; invalidFields: string[] }[] => {
  return animals.map((animal, index) => {
    const props = Object.keys(animal)
    const currentRequiredFields = [...requiredFields]

    const invalidFields = currentRequiredFields.filter(requiredField => {
      if (!props.includes(requiredField)) {
        return true
      }

      const value = animal[requiredField]
      return !value
    })

    if (additionalValidation) {
      additionalValidation(animal, invalidFields)
    }

    return {
      index,
      invalidFields,
    }
  })
}

const appendValidationMessages = <T extends { error_message?: string }>(
  animals: T[],
  validationResults: { index: number; invalidFields: string[] }[]
): T[] => {
  const updatedAnimals = animals.map((animal, index) => {
    const error = validationResults.find(e => e.index === index)
    if (error && error.invalidFields.length > 0) {
      const errorMessages = error.invalidFields.map(
        field =>
          ErrorDictionary[
            field.toUpperCase() as keyof typeof ErrorDictionary
          ] || field
      )
      return {
        ...animal,
        error_message: `${Messages.INVALID_FIELDS_LIST} ${errorMessages.join(
          ', '
        )}`,
      }
    } else {
      return animal.error_message
        ? { ...animal, error_message: undefined }
        : animal
    }
  })

  return updatedAnimals
}

const validateAndUpdateAnimals = <T extends { error_message?: string }>(
  animals: T[],
  requiredFields: string[],
  updateCallback: (updatedAnimals: T[]) => void,
  additionalValidation?: (animal: T, invalidFields: string[]) => void
): void => {
  const validationResults = findInvalidFields(
    animals,
    requiredFields,
    additionalValidation
  )
  const updatedAnimals = appendValidationMessages(animals, validationResults)
  updateCallback(updatedAnimals)
}

const validateRequiredField = (
  animal: Record<string, unknown>,
  field: string,
  invalidFields: string[]
) => {
  if (!animal[field]) {
    invalidFields.push(field)
  }
}

export {
  clearEmptyFields,
  isEndDateBeforeStartDate,
  isSelectItem,
  validateRequiredField,
  validateAndUpdateAnimals,
}
