import { ToastContainer } from 'react-toastify'

import { UserProvider } from './auth-user'
import { FeatureFlagProvider } from './feature-flags'
import { MenuProvider } from './menu'
import { ServiceOrderProvider } from './service-orders'

const AppProviders: React.FC = ({ children }) => (
  <>
    <FeatureFlagProvider>
      <ServiceOrderProvider>
        <UserProvider>
          <MenuProvider>{children}</MenuProvider>
          <ToastContainer />
        </UserProvider>
      </ServiceOrderProvider>
    </FeatureFlagProvider>
  </>
)

export { AppProviders }
