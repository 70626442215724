import { Table } from '@tanstack/react-table'
import React from 'react'

import { ButtonIconPosition } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { AnimalCreateProps } from 'app/core/types/animal'

type AnimalReviewTemplateProps = {
  handleAnimals: () => void
  sector: string
  animals: AnimalCreateProps[]
  table: Table<AnimalCreateProps>
}

const AnimalReviewTemplate: React.FC<AnimalReviewTemplateProps> = ({
  handleAnimals,
  sector,
  animals,
  table,
}) => (
  <>
    {animals && (
      <BoxedLayout
        backButton
        title="Revisar cadastro de animais"
        headerLargeButtonAction={handleAnimals}
        headerLargeButtonLabel="Salvar"
        headerLargeButtonIconPosition={ButtonIconPosition.right}
        date={new Date().toISOString()}
        headerHighlightedLabel="Setor"
        headerHighlightedData={sector}
        isChildrenSticky
      >
        <BoxedTable
          data={animals}
          table={table}
          isTableHeaderSticky
          title="Animais"
          quantityLabel="Entrada"
          disableNavigation
        />
      </BoxedLayout>
    )}
  </>
)

export { AnimalReviewTemplate }
