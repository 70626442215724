import { AnimalListProps } from 'app/core/types/animal'
import {
  BreedingGroupDraftProps,
  BreedingGroupStateProps,
} from 'app/core/types/breeding'
import { StorageKeys } from 'app/core/types/storage'
import { addToast } from 'app/core/utils'

const clearBreedingGroupStorage = () => {
  localStorage.removeItem(StorageKeys.breedingGroup)
  localStorage.removeItem(StorageKeys.breedingGroupFailed)
}

const filterNewAnimals = (
  newAnimals: AnimalListProps[],
  existingAnimals: AnimalListProps[]
) => {
  return newAnimals.filter(
    newAnimal =>
      !existingAnimals.some(
        existingAnimal => existingAnimal.id === newAnimal.id
      )
  )
}

const getBreedingGroup = () => {
  return JSON.parse(
    localStorage.getItem(StorageKeys.breedingGroup) as string
  ) as BreedingGroupStateProps
}

const getDuplicatedAnimals = (
  newAnimals: AnimalListProps[],
  existingAnimals: AnimalListProps[]
) => {
  return newAnimals.filter(newAnimal =>
    existingAnimals.some(existingAnimal => existingAnimal.id === newAnimal.id)
  )
}

const notifyDuplicatedAnimals = (duplicatedAnimals: AnimalListProps[]) => {
  duplicatedAnimals.forEach(animal => {
    addToast({
      message: `Animal já adicionado: ${
        animal.stock_number || animal.birth_number || animal.id
      }`,
    })
  })
}

const setBreedingGroup = (
  group: BreedingGroupDraftProps,
  males: AnimalListProps[],
  females: AnimalListProps[]
) => {
  const state: BreedingGroupStateProps = {
    group,
    animals: {
      males,
      females,
    },
  }

  localStorage.setItem(StorageKeys.breedingGroup, JSON.stringify(state))
}

const formattedBreedingStationName = (name: string) => name.split(':')[1].trim()

export {
  clearBreedingGroupStorage,
  filterNewAnimals,
  formattedBreedingStationName,
  getBreedingGroup,
  getDuplicatedAnimals,
  notifyDuplicatedAnimals,
  setBreedingGroup,
}
