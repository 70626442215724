import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primereact/autocomplete'
import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Controller } from 'react-hook-form'

import classNames from 'classnames'
import * as yup from 'yup'

import {
  CheckboxButton,
  Chip,
  ChipColorVariant,
  Icon,
  IconNames,
  Select,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { Divider } from 'components/atoms/divider'
import { CustomModal } from 'components/molecules'
import { EvaluateAnimal, useForm } from 'components/organisms'
import { CHIP_SEX } from 'components/templates/animal-layout/consts'
import { Col, Row } from 'components/templates/animal-layout/helpers'

import { useSector } from 'app/core/hooks'
import { getReadAllAnimals } from 'app/core/services'
import {
  AnimalFilterProps,
  AnimalListProps,
  AnimalSexLabel,
  IAnimalEntity,
} from 'app/core/types/animal'
import {
  MatingRecommendationProps,
  ModalSteps,
} from 'app/core/types/mating-recommendation'
import {
  addToast,
  dateTimeFormat,
  dateToday,
  formatWeight,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

export type MatingRecommendationModalProps = {
  animal?: IAnimalEntity
  isMatingRecommendation: boolean
  isModalOpen: boolean
  selectedCalf?: AnimalListProps
  selectedRecommendation?: MatingRecommendationProps
  setSelectedRecommendation: Dispatch<
    SetStateAction<MatingRecommendationProps | undefined>
  >
  handleDisposal: (destinationSectorId: number) => Promise<void>
  handleModal: (isMatingRecommendation?: boolean) => void
  handleNewEvaluation: (data: Record<string, unknown>) => Promise<void>
  handleRecommendation: (
    date: string,
    maleName: string,
    maleId?: number,
    isEditing?: boolean
  ) => Promise<void>
}

const MatingRecommendationModal: React.FC<MatingRecommendationModalProps> = ({
  animal,
  isMatingRecommendation,
  isModalOpen,
  selectedCalf,
  selectedRecommendation,
  setSelectedRecommendation,
  handleDisposal,
  handleModal,
  handleNewEvaluation,
  handleRecommendation,
}) => {
  const { sectors } = useSector()

  const [activeStep, setActiveStep] = useState<ModalSteps>(
    ModalSteps.EVALUATION_STEP
  )
  const [filledEvaluation, setFilledEvaluation] = useState<boolean>()
  const [isSemen, setIsSemen] = useState<boolean>(true)
  const [selectedAnimal, setSelectedAnimal] = useState<AnimalListProps>()
  const [animalSuggestions, setAnimalSuggestions] = useState<AnimalListProps[]>(
    []
  )
  const [animalIsNotLinked, setAnimalIsNotLinked] = useState<boolean>(false)
  const [isBackButtonDisabled, setIsBackButtonDisabled] =
    useState<boolean>(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false)
  const [moveToDisposal, setMoveToDisposal] = useState<boolean>(false)
  const [animalFarmSectors, setAnimalFarmSectors] =
    useState<SelectItemProps[]>()

  const isEditing = !!selectedRecommendation

  const searchAnimals = async (
    event: AutoCompleteCompleteEvent
  ): Promise<void> => {
    const { query } = event
    const minimumNumberOfCharacters = 3

    if (query === '' || query.length < minimumNumberOfCharacters) {
      return
    }

    const isSemen = getValues('semen')

    const filters = {
      belongs_to_herd: isSemen ? 'false' : 'true',
      ...(!isSemen && { sex: AnimalSexLabel.male }),
      like_stock_number_or_birth_number_or_name_or_electronic_eartag: query,
    } as AnimalFilterProps

    try {
      const animals = await getReadAllAnimals(filters)

      if (animals.items.length === 0) {
        setAnimalSuggestions([])
        return
      }

      setAnimalSuggestions(animals.items)
    } catch (e) {
      addToast({ message: Messages.ANIMALS_NOT_FOUND })
    }
  }

  const handleModalAction = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const isEvaluationFilled = !!data.score

      setFilledEvaluation(isEvaluationFilled)

      if (isMatingRecommendation) {
        if (activeStep === ModalSteps.EVALUATION_STEP && isEvaluationFilled) {
          try {
            await handleNewEvaluation(data)
          } catch (e) {
            setActiveStep(ModalSteps.EVALUATION_STEP)
          }
        }

        setActiveStep(ModalSteps.RECOMMENDATION_STEP)

        const animalNotSelected =
          !data.animal && (!selectedAnimal || animalSuggestions.length === 0)
        if (animalNotSelected) setIsSubmitButtonDisabled(true)

        if (activeStep === ModalSteps.RECOMMENDATION_STEP) {
          const animal = data.animal as AnimalListProps
          const destinationSector = data.sector as SelectItemProps
          const moveToDisposal = getValues('moveToDisposal')

          handleCloseModal()

          await handleRecommendation(
            data.date as string,
            animalIsNotLinked ? (data.animal as string) : animal.name || '',
            animal.id,
            isEditing
          )

          if (moveToDisposal) {
            await handleDisposal(Number(destinationSector.value))
          }
        }

        return
      }

      await handleNewEvaluation(data)

      handleCloseModal()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeStep,
      handleDisposal,
      handleNewEvaluation,
      handleRecommendation,
      isEditing,
      isMatingRecommendation,
      animalIsNotLinked,
      selectedAnimal,
      selectedRecommendation,
    ]
  )

  const validationSchemaEvaluation = yup.object({
    score: yup.string().required(Messages.EVALUATION_SCORE_REQUIRED).nullable(),
  })

  const validationSchemaMatingRecommendation = yup.object({
    score: yup.string().nullable(),
  })

  const { Form, handleSubmit, reset, getValues, control } = useForm({
    onSubmit: handleModalAction,
    validationSchema: isMatingRecommendation
      ? validationSchemaMatingRecommendation
      : validationSchemaEvaluation,
  })

  const handleCloseModal = useCallback((): void => {
    reset()
    setAnimalIsNotLinked(false)
    setSelectedAnimal(undefined)
    setSelectedRecommendation(undefined)
    setMoveToDisposal(false)
    setActiveStep(ModalSteps.EVALUATION_STEP)
    handleModal()
  }, [handleModal, setSelectedRecommendation, reset])

  const handleSecondaryButtonAction = useCallback((): void => {
    if (
      isMatingRecommendation &&
      activeStep === ModalSteps.RECOMMENDATION_STEP
    ) {
      setActiveStep(ModalSteps.EVALUATION_STEP)
      return
    }

    setFilledEvaluation(undefined)
    handleCloseModal()
  }, [activeStep, handleCloseModal, isMatingRecommendation])

  const handleSemenChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setIsSemen(e.currentTarget.checked)
    },
    []
  )

  const handleMoveToDisposal = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setMoveToDisposal(e.currentTarget.checked)
    },
    []
  )

  useEffect(() => {
    if (animal && sectors) {
      const onlyDisposalSectors = sectors?.filter(sector =>
        sector.name.toLowerCase().includes('descarte')
      )
      const animalFarmId = animal.basic_information.sector.farm_id
      const animalFarmSectors = onlyDisposalSectors
        .filter(sector => sector.farm?.id === animalFarmId)
        .map(sector => ({
          label: sector.name,
          value: String(sector.id),
        }))
      setAnimalFarmSectors(animalFarmSectors)
    }
  }, [sectors, animal])

  useEffect(() => {
    const inactiveAnimal = !animal?.header.is_active || !selectedCalf?.is_active
    const recommendationStep =
      inactiveAnimal || !selectedCalf || selectedRecommendation

    setActiveStep(
      recommendationStep
        ? ModalSteps.RECOMMENDATION_STEP
        : ModalSteps.EVALUATION_STEP
    )
  }, [
    animal,
    isMatingRecommendation,
    selectedCalf,
    selectedRecommendation,
    handleCloseModal,
  ])

  useEffect(() => {
    if (activeStep === ModalSteps.RECOMMENDATION_STEP) {
      const hasNoCalf = !selectedCalf || !selectedCalf?.is_active
      setIsBackButtonDisabled(hasNoCalf)
    }
  }, [activeStep, selectedCalf])

  const autoCompleteItemTemplate = (item: AnimalListProps): JSX.Element => {
    return (
      <ul>
        {item.name && (
          <li>
            Nome do animal:
            <span>{item.name}</span>
          </li>
        )}
        {item.stock_number && (
          <li>
            Nº de Plantel:
            <span>{item.stock_number}</span>
          </li>
        )}
        {item.birth_number && (
          <li>
            Nº de Nascimento:
            <span>{item.birth_number}</span>
          </li>
        )}
      </ul>
    )
  }

  const handleAutoCompleteHide = (): void => {
    if (animalSuggestions.length === 0 || !selectedAnimal) {
      setAnimalIsNotLinked(true)
    } else {
      setAnimalIsNotLinked(false)
    }

    setIsSubmitButtonDisabled(false)
  }

  const handleAutoCompleteKeyUp = (
    e: KeyboardEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value === '') {
      setIsSubmitButtonDisabled(true)
      setAnimalIsNotLinked(false)
      setSelectedAnimal(undefined)

      return
    }

    setIsSubmitButtonDisabled(false)
  }

  useEffect(() => {
    if (activeStep === ModalSteps.EVALUATION_STEP) {
      setIsSubmitButtonDisabled(false)
    }
  }, [activeStep])

  return (
    <CustomModal
      modalIsOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      modalHeader=""
      primaryButtonLabel={
        isMatingRecommendation
          ? activeStep === ModalSteps.EVALUATION_STEP
            ? 'Recomendar'
            : 'Confirmar'
          : 'Avaliar'
      }
      primaryButtonAction={handleSubmit(handleModalAction)}
      primaryButtonDisabled={isSubmitButtonDisabled}
      secondaryButtonLabel={
        isMatingRecommendation
          ? activeStep === ModalSteps.EVALUATION_STEP
            ? 'Cancelar'
            : 'Voltar'
          : 'Cancelar'
      }
      secondaryButtonAction={handleSecondaryButtonAction}
      secondaryButtonDisabled={isBackButtonDisabled}
    >
      <Form>
        {isMatingRecommendation && !isEditing && (
          <div className={styles.modalSteps}>
            <button
              className={classNames(
                styles.modalStepsButton,
                activeStep > ModalSteps.EVALUATION_STEP &&
                  styles.modalStepsButtonComplete,
                activeStep === ModalSteps.EVALUATION_STEP &&
                  styles.modalStepsButtonActive
              )}
              type="button"
            >
              <span
                className={classNames(
                  styles.modalStepsButtonNumber,
                  activeStep === ModalSteps.RECOMMENDATION_STEP &&
                    !filledEvaluation &&
                    styles.modalStepsButtonNumberAlert
                )}
              >
                {activeStep === ModalSteps.RECOMMENDATION_STEP ? (
                  filledEvaluation ? (
                    <Icon name={IconNames['checkmark']} />
                  ) : (
                    <Icon name={IconNames['close-circle']} />
                  )
                ) : (
                  1
                )}
              </span>
              Avaliar cria
            </button>
            <span className={styles.modalStepsSeparator}></span>
            <button
              className={classNames(
                styles.modalStepsButton,
                activeStep === ModalSteps.RECOMMENDATION_STEP &&
                  styles.modalStepsButtonActive
              )}
              type="button"
            >
              <span className={styles.modalStepsButtonNumber}>2</span>
              Recomendar animal
            </button>
          </div>
        )}

        {activeStep === ModalSteps.EVALUATION_STEP && (
          <>
            {selectedCalf && (
              <>
                <Typography
                  text={
                    isMatingRecommendation
                      ? 'Avaliação da última cria'
                      : 'Nova avaliação de cria'
                  }
                  variant={TypographyVariant.h5}
                  className={styles.modalHeaderText}
                />

                <div className={styles.selectedCalf}>
                  <Typography
                    text="Informações da cria"
                    variant={TypographyVariant.h6}
                  />
                  <Divider darker />

                  <Row className={styles.selectedCalfRow}>
                    <Col>
                      <Typography
                        text="Nº de Plantel"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Typography
                        text={selectedCalf.stock_number || 'Sem número'}
                        variant={TypographyVariant.p}
                      />
                    </Col>

                    <Col>
                      <Typography
                        text="Nº de Nascimento"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Typography
                        text={selectedCalf.birth_number || 'Sem número'}
                        variant={TypographyVariant.p}
                      />
                    </Col>

                    <Col>
                      <Typography
                        text="Sexo"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Chip colorVariant={CHIP_SEX[selectedCalf.sex]} />
                    </Col>

                    {selectedCalf.evaluations && (
                      <>
                        <Col>
                          <Typography
                            text="Peso na desmama"
                            variant={TypographyVariant.caption3}
                            altColor
                          />
                          <Typography
                            text={formatWeight(
                              selectedCalf.evaluations[0]?.weaning_weight
                            )}
                            variant={TypographyVariant.p}
                          />
                        </Col>

                        <Col>
                          <Typography
                            text="Ganho médio diário"
                            variant={TypographyVariant.caption3}
                            altColor
                          />
                          <Typography
                            text={formatWeight(
                              selectedCalf.evaluations[0]?.average_daily_gain
                            )}
                            variant={TypographyVariant.p}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </>
            )}

            <EvaluateAnimal animal={selectedCalf} form={Form} />
          </>
        )}

        {activeStep === ModalSteps.RECOMMENDATION_STEP && (
          <div className={styles.matingRecommendationForm}>
            <Typography
              text={
                isEditing
                  ? 'Editar recomendação para acasalamento'
                  : 'Recomendar animal para acasalamento'
              }
              variant={TypographyVariant.h6}
            />

            {isEditing && (
              <div className={styles.selectedCalf}>
                <Typography
                  text="Recomendação atual"
                  variant={TypographyVariant.h6}
                />
                <Divider darker />

                <Row className={styles.selectedCalfRow}>
                  <Col>
                    <Typography
                      text="Nome"
                      variant={TypographyVariant.caption3}
                      altColor
                    />
                    <Typography
                      text={selectedRecommendation?.male_name}
                      variant={TypographyVariant.p}
                    />
                  </Col>

                  <Col>
                    <Typography
                      text="Nº de Plantel"
                      variant={TypographyVariant.caption3}
                      altColor
                    />
                    <Typography
                      text={
                        selectedRecommendation?.male_stock_number ||
                        'Sem número'
                      }
                      variant={TypographyVariant.p}
                    />
                  </Col>

                  <Col>
                    <Typography
                      text="Nº de Nascimento"
                      variant={TypographyVariant.caption3}
                      altColor
                    />
                    <Typography
                      text={
                        selectedRecommendation?.male_birth_number ||
                        'Sem número'
                      }
                      variant={TypographyVariant.p}
                    />
                  </Col>

                  <Col>
                    <Typography
                      text="Data"
                      variant={TypographyVariant.caption3}
                      altColor
                    />
                    <Typography
                      text={dateTimeFormat(
                        String(selectedRecommendation?.date)
                      )}
                      variant={TypographyVariant.p}
                    />
                  </Col>

                  {selectedRecommendation?.male_is_active && (
                    <Col>
                      <Typography
                        text="Status"
                        variant={TypographyVariant.caption3}
                        altColor
                      />
                      <Chip
                        colorVariant={
                          selectedRecommendation?.male_is_active
                            ? ChipColorVariant.statusActive
                            : ChipColorVariant.statusInactive
                        }
                      />
                    </Col>
                  )}
                </Row>
              </div>
            )}

            <Divider darker />

            <div className={styles.matingRecommendationFormFields}>
              <Form.InputText
                name="date"
                label="Data"
                htmlType="date"
                value={dateToday}
                readOnly
              />
              <div className={styles.matingRecommendationFormFieldsAnimal}>
                <label htmlFor="animal">Informar animal</label>
                <div
                  className={styles.matingRecommendationFormFieldsAnimalInputs}
                >
                  <Controller
                    name="animal"
                    control={control}
                    render={({ field }): JSX.Element => (
                      <AutoComplete
                        autoFocus
                        inputId={field.name}
                        value={field.value}
                        field="stock_number"
                        onChange={field.onChange}
                        inputRef={field.ref}
                        suggestions={animalSuggestions}
                        completeMethod={searchAnimals}
                        itemTemplate={autoCompleteItemTemplate}
                        onKeyUp={handleAutoCompleteKeyUp}
                        onHide={handleAutoCompleteHide}
                        onSelect={(e: AutoCompleteSelectEvent): void =>
                          setSelectedAnimal(e.value)
                        }
                        emptyMessage={Messages.ANIMALS_NOT_FOUND}
                        showEmptyMessage
                        placeholder={Messages.ANIMAL_SEARCH_MATING_RECOMMENDATION}
                      />
                    )}
                  />
                  <CheckboxButton
                    containerClassName={
                      styles.matingRecommendationFormFieldsCheckbox
                    }
                    name="semen"
                    options={[{ value: 'true', label: 'Sêmen' }]}
                    defaultChecked={{ value: String(isSemen) }}
                    onChange={handleSemenChange}
                  />
                </div>
              </div>
            </div>

            {animalIsNotLinked && (
              <>
                <div className={styles.animalIsNotLinked}>
                  Nenhum animal do sistema vinculado.
                </div>

                <div className={styles.moveToDisposal}>
                  <CheckboxButton
                    name="moveToDisposal"
                    options={[
                      { value: 'true', label: 'Mover animal para descarte' },
                    ]}
                    onChange={handleMoveToDisposal}
                  />

                  {moveToDisposal && (
                    <Select
                      options={animalFarmSectors}
                      name="sector"
                      placeholder="Selecione o setor"
                      defaultMenuIsOpen
                    />
                  )}
                </div>
              </>
            )}

            {selectedAnimal && !animalIsNotLinked && (
              <div className={styles.animalLinked}>
                Animal do sistema vinculado.
              </div>
            )}
          </div>
        )}
      </Form>
    </CustomModal>
  )
}

export { MatingRecommendationModal }
