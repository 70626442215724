import React from 'react'

import { EnvironmentMessage } from 'components/atoms'
import { Menu } from 'components/organisms'

import { AnimalHistoryTypes, IAnimalEntity } from 'app/core/types/animal'

import {
  AnimalAdvancedInfo,
  AnimalBasicInfo,
  AnimalHeader,
  AnimalHistory,
} from './helpers'
import styles from './styles.module.scss'

export interface IAnimalLayoutProps {
  animal: IAnimalEntity
  buttonAction: () => void
  isAdminUser: boolean
}

const AnimalLayout: React.FC<IAnimalLayoutProps> = ({
  animal,
  buttonAction,
  isAdminUser,
}) => {
  const lastShutdown = animal.history
    .filter(item => item.type === AnimalHistoryTypes.animal_shutdown)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]

  return (
    <>
      <EnvironmentMessage />

      <div className={styles.page}>
        <Menu />

        <main className={styles.main}>
          <div className={styles.stickyHeader}>
            <AnimalHeader
              header={animal.header}
              buttonAction={buttonAction}
              shutdown={lastShutdown}
              isAdminUser={isAdminUser}
            />

            <div className={styles.animalInfo}>
              <AnimalBasicInfo
                additionalInformation={animal.additional_information}
                basicInfo={animal.basic_information}
                isCompositeBreed={animal.additional_information.composite_breed}
              />
            </div>
          </div>

          <div className={styles.animalInfo}>
            <AnimalAdvancedInfo
              sex={animal.header.sex}
              breed={animal.basic_information.breed}
              additionalInformation={animal.additional_information}
              genealogy={animal.genealogy}
              reproduction={animal.reproduction}
              association={animal.association}
            />
            {animal.history.length > 0 && (
              <AnimalHistory history={animal.history} />
            )}
          </div>
        </main>
      </div>
    </>
  )
}

export { AnimalLayout }
