import { SelectItemProps } from 'components/atoms'

import { AnimalListProps } from './animal'
import { DefaultPagination, ErrorResponse, FailMessage } from './system'
import { AbczSyncStatus } from './abcz'

export enum ImplantUseList {
  Primeiro = 'Primeiro',
  Segundo = 'Segundo',
  Terceiro = 'Terceiro',
  Quarto = 'Quarto',
  Quinto = 'Quinto',
}

export type ProtocolActionProps = {
  id: number
  day: number
  action: string
  dose: number
  provider: string
}

export type ProtocolValuesProps = {
  actions: Omit<ProtocolActionProps, 'id'>[]
}

export type ProtocolProps = {
  id: number
  name: string
  date_created: string
  actions: ProtocolActionProps[]
  has_vinculated_iatf_schedule: boolean
}

export type ProtocolFilterRequestData = {
  init_date: string
  final_date: string
}

export type ProtocolCreateRequestData = {
  name: string
}

export type ProtocolReadResponseData = {
  items: ProtocolProps[]
} & DefaultPagination

export type ScheduleProps = {
  id: number
  name: string
  date_start: string
  date_end: string
  total_females: number
  breeding_station_id: number
  breeding_station_name: string
  hormonal_protocol_id: number
  hormonal_protocol_name: string
  hormonal_protocol_day: number
}

export type ScheduleFilterProps = {
  name: string
  init_date: string
  final_date: string
  sector_id: number
  farm_id: number
  breeding_station_id: number
  schedule_id: number
  schedule_name: string
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type ScheduleAnimalActionProps = {
  id: number
  executed: string | boolean | undefined
  date_created: string
  iatf_schedule_animal_id: number
  hormonal_action_id: number
  hormonal_action_name?: string
  hormonal_action_day: number
}

export type ScheduleAnimalProps = {
  id: number
  executed: boolean
  body_condition_score: number
  implanted: boolean
  implant_used: string
  date_created: string
  animal: AnimalListProps
  actions: ScheduleAnimalActionProps[]
  is_active: boolean
}

export type ScheduleFailedAnimalProps = {
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
  error_message: string
}

export type ScheduleAnimalResponseData = {
  items: ScheduleAnimalProps[]
} & DefaultPagination

export type ScheduleCreateRequestData = Omit<
  ScheduleProps,
  'id' | 'breeding_station_name' | 'hormonal_protocol_name'
>

export type ScheduleCreateResponseData = ScheduleAnimalProps & ErrorResponse

export type ScheduleCreateRequestFormatData = {
  breed_station_id: SelectItemProps
  hormonal_protocol_id: SelectItemProps
} & ScheduleCreateRequestData

export type ScheduleUpdateAnimalRequestData = {
  actions: Pick<ScheduleAnimalActionProps, 'id' | 'executed'>[]
} & Pick<
  ScheduleAnimalProps,
  'id' | 'body_condition_score' | 'implant_used' | 'implanted'
>

export type ScheduleReadResponseData = {
  items: ScheduleProps[]
} & DefaultPagination

export enum InseminationTypes {
  artificial_insemination = 'Inseminação artificial',
  natural_cover = 'Cobertura natural',
}

export const INSEMINATION_TYPE_OPTIONS = Object.values(InseminationTypes).map(
  value => ({ label: value, value })
)

export enum HeatTypes {
  hormonal_protocol = 'Protocolo Hormonal',
  natural_heat = 'Cio Natural',
}

export const HEAT_TYPE_OPTIONS = Object.values(HeatTypes).map(value => ({
  label: value,
  value,
}))

export type InseminationFilterProps = {
  animal_name: string
  init_date: string
  final_date: string
  sector_id: number
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type InseminationProps = {
  id: number
  type: InseminationTypes
  animals_count?: number
  date: string
  breeding_station_id: number
  breeding_station_name: string
  iatfschedule_id: number | null
  iatfschedule_name: string | null
}

export type InseminationAnimalProps = {
  id: number
  animal_id: number
  electronic_eartag: string
  stock_number: string
  insemination_date: string
  breeding_animal_id: number
  heat_type: HeatTypes
  semen_implanted: number
  responsible_inseminator: string
  inseminator_id: number
  semen_implanted_stock_number?: string
  breeding_animal_stock_number?: string
  error_message?: string
  abcz_status?: AbczSyncStatus
  abcz_status_message?: string
}

export type InseminationAnimalResponseData = {
  items: InseminationAnimalProps[]
} & DefaultPagination

export type InseminationReadResponseData = {
  items: Omit<
    InseminationProps,
    'breeding_station_id' | 'iatfschedule_id' | 'iatfschedule_name'
  >[]
} & DefaultPagination

export type InseminationAnimalReadResponseData = {
  items: InseminationAnimalProps[]
} & DefaultPagination

export type InseminationCreateFormatData = {
  breeding_station_id: SelectItemProps
  iatfschedule_id: SelectItemProps | undefined
  type: SelectItemProps
} & Pick<InseminationProps, 'date'>

export type InseminationCreateRequestData = Pick<
  InseminationProps,
  'type' | 'breeding_station_id' | 'date' | 'iatfschedule_id'
>

export type InseminationCreateResponseData = Pick<
  InseminationProps,
  'id' | 'date' | 'breeding_station_id' | 'iatfschedule_id' | 'type'
>

export type InseminationAnimalCreateRequestData = Pick<
  InseminationAnimalProps,
  'animal_id' | 'insemination_date' | 'heat_type'
> &
  Partial<
    Pick<
      InseminationAnimalProps,
      'semen_implanted' | 'responsible_inseminator' | 'breeding_animal_id'
    >
  >

export type InseminationAnimalCreateResponseData = {
  data: InseminationAnimalProps[]
  errors: FailMessage[]
}

export type InseminationAnimalUpdateRequestData = Partial<
  Pick<
    InseminationAnimalProps,
    | 'insemination_date'
    | 'heat_type'
    | 'semen_implanted'
    | 'responsible_inseminator'
    | 'breeding_animal_id'
    | 'semen_implanted_stock_number'
    | 'breeding_animal_stock_number'
  >
>

export type InseminationAnimalUpdateResponseData = {
  type: InseminationTypes
  insemination_group_id: number
} & Omit<InseminationAnimalProps, 'stock_number' | 'electronic_eartag'>

export type UpdateAnimalInseminationData = {
  inseminationId: number | undefined
  inseminatedAnimalId: number
  request: InseminationAnimalUpdateRequestData
}

export type ImportedInseminationsProps = {
  stock_number: string
  electronic_eartag: string
  insemination_date: string
  heat_type: string
  semen_implanted_stock_number?: string
  breeding_animal_stock_number?: string
  responsible_inseminator?: string
  [index: number]: string
}

export type ImportedIATFProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  body_condition_score: string
  implanted: boolean
  implant_used: string
  [index: number]: string
}

export type InseminationReportResponse = {
  id: number
  breeding_station_id?: number
  breeding_station_name?: string
  iatf_schedule_id?: number
  iatf_schedule_name?: string
  animal_id: number
  birth_number?: string
  electronic_eartag?: string
  stock_number?: string
  insemination_date?: Date
  insemination_type?: string
  heat_type?: string
  breeding_animal_id?: number
  breeding_animal_stock_number?: string
  breeding_group_breeder_id?: number
  breeding_group_name?: string
  semen_implanted?: number
  semen_implanted_stock_number?: string
  inseminator_id?: number
  responsible?: string
}

export type IATFReportResponse = {
  id: number
  name: string
  date_start: Date
  date_end: Date
  breeding_station_id?: number
  breeding_station_name?: string
  hormonal_protocol_id?: number
  hormonal_protocol_name?: string
  animal_id?: number
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
  iatf_schedule_animal_id?: number
  body_condition_score?: number
  implanted?: boolean
  implant_used?: string
  date_created?: Date
  last_calving?: Date
  actions?: string
}
