import { ColumnDef } from '@tanstack/react-table'

import { RemoveButton } from 'components/molecules'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { AnimalListProps, AnimalSexLabel } from 'app/core/types/animal'

const breedingGroupAddMalesColumns = (
  handleRemoveAnimal?: (id: number, sex: AnimalSexLabel) => void
): ColumnDef<AnimalListProps>[] => [
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Pai ou Grupo de Repasse',
    accessorKey: 'father_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Avô Paterno',
    accessorKey: 'grandfather_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <>
        {handleRemoveAnimal && (
          <RemoveButton
            itemId={info.row.original.id}
            onClick={(): void =>
              handleRemoveAnimal(
                info.row.original.id,
                info.row.original.sex as AnimalSexLabel
              )
            }
          />
        )}
      </>
    ),
  },
]

export { breedingGroupAddMalesColumns }
