import { Table } from '@tanstack/react-table'
import React, { useCallback } from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import {
  AnimalDrawer,
  BoxedTable,
  ISelectButtonItem,
} from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { ReproductionLayout } from 'components/templates'

import { formattedBreedingStationName } from 'app/core/hooks/breeding/group/helpers'
import {
  AnimalDrawerType,
  AnimalListProps,
  AnimalSexLabel,
  AnimalSexPlural,
} from 'app/core/types/animal'
import { BreedingGroupDraftProps } from 'app/core/types/breeding'
import { Messages } from 'config/messages'

type BreedingGroupAddAnimalsTemplateProps = {
  group: BreedingGroupDraftProps
  females?: AnimalListProps[]
  tableFemales: Table<AnimalListProps>
  males?: AnimalListProps[]
  tableMales: Table<AnimalListProps>
  selectedSex?: AnimalSexLabel
  isDrawerOpen: boolean
  handleToggleDrawer: (selectedSex?: AnimalSexLabel) => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
  handleGoToReview: () => void
  headerSelectButtonItems: ISelectButtonItem[]
}

const BreedingGroupAddAnimalsTemplate: React.FC<BreedingGroupAddAnimalsTemplateProps> =
  ({
    group,
    females,
    tableFemales,
    males,
    tableMales,
    selectedSex,
    isDrawerOpen,
    handleToggleDrawer,
    onSubmit,
    isLoading,
    handleGoToReview,
    headerSelectButtonItems,
  }) => {
    const defaultBoxedTableProps = {
      disableLastCellNavigation: true,
      mainButtonLabel: 'Incluir',
      mainButtonIcon: IconNames['add-circle'],
      noDataMessage: Messages.ANIMAL_NOT_ADDED,
    }

    const handleFilter = useCallback(
      (sex: AnimalSexLabel): void => {
        handleToggleDrawer(sex)
      },
      [handleToggleDrawer]
    )

    return (
      <>
        {group && (
          <>
            <ReproductionLayout
              backButton
              title={group.name}
              initialDate={group.init_date}
              finalDate={group.final_date}
              breedingStationId={Number(group.breeding_station.value)}
              breedingStationName={formattedBreedingStationName(
                group.breeding_station.label
              )}
              headerLargeButtonLabel="Revisar"
              headerLargeButtonAction={handleGoToReview}
              headerLargeButtonIcon={
                <Icon name={IconNames['chevron-forward']} />
              }
              headerSelectButtonLabel="Planilha"
              headerSelectButtonItems={headerSelectButtonItems}
              headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
              headerSelectButtonIconPosition={ButtonIconPosition.right}
              headerSelectButtonLoading={isLoading}
              farmId={Number(group.farm_id.value)}
              farmName={group.farm_id.label}
            >
              {females && (
                <BoxedTable
                  title={AnimalSexPlural.female}
                  table={tableFemales}
                  data={females}
                  mainButtonAction={(): void =>
                    handleFilter(AnimalSexLabel.female)
                  }
                  isLoading={isLoading}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
              {males && (
                <BoxedTable
                  title={AnimalSexPlural.male}
                  table={tableMales}
                  data={males}
                  mainButtonAction={(): void =>
                    handleFilter(AnimalSexLabel.male)
                  }
                  isLoading={isLoading}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
            </ReproductionLayout>

            <AnimalDrawer
              includeTitle={`Incluir ${
                selectedSex && AnimalSexPlural[selectedSex]
              }`}
              filterTitle={`Filtrar ${
                selectedSex && AnimalSexPlural[selectedSex]
              }`}
              isOpen={isDrawerOpen}
              onToggle={handleToggleDrawer}
              onSubmit={onSubmit}
              drawerType={AnimalDrawerType.include}
              disabledMessage="É necessário preencher algum parâmetro para aplicar a busca"
              {...(selectedSex === AnimalSexLabel.female && {
                hasIATFScheduleDropdown: true,
              })}
              selectedFarmId={Number(group.farm_id.value)}
              disableIfEmpty
            />
          </>
        )}
      </>
    )
  }

export { BreedingGroupAddAnimalsTemplate }
