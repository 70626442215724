import { SelectItemProps } from 'components/atoms'

import { AnimalListProps } from './animal'
import { ProtocolProps } from './hormonal'
import { BreedProps, DefaultPagination } from './system'

export enum BreedingServiceDrawerOptions {
  filter = 'filter',
  add = 'add',
}

export enum BreedingAnimalHandlerOptions {
  add = 'add_animal',
  remove = 'remove_animal',
}

export type DefaultLinkProps = {
  id: number
  name: string
}

export type BreedingServiceAnimalProps = {
  name: string
  reproductive_status?: string
  father_number?: string
  grandfather_number?: string
} & Pick<
  AnimalListProps,
  | 'id'
  | 'birth_number'
  | 'stock_number'
  | 'electronic_eartag'
  | 'birth_date'
  | 'last_calving'
>

export type BreedingServiceAnimalReport = BreedingServiceAnimalProps & {
  sex: string
}

export type BreedingStationProps = {
  id: number
  name: string
  init_date: string
  final_date: string
  males: number
  females: number
  breeding_groups?: DefaultLinkProps[]
  hormonal_protocol?: DefaultLinkProps
  is_active: boolean
  farm_id?: number
}

export type BreedingStationTemplateProps = {
  males: BreedingServiceAnimalProps[]
  females: BreedingServiceAnimalProps[]
} & Pick<
  BreedingStationProps,
  | 'id'
  | 'name'
  | 'init_date'
  | 'final_date'
  | 'breeding_groups'
  | 'hormonal_protocol'
  | 'is_active'
>

export type BreedingStationAnimalsProps = {
  id: number
  name: string
  init_date: string
  final_date: string
  transfer_group: number
  is_active: boolean
  males: BreedingServiceAnimalProps[]
  females: BreedingServiceAnimalProps[]
  hormonal_protocol: ProtocolProps
}

export type BreedingStationReadResponseData = {
  items: BreedingStationProps[]
} & DefaultPagination

export type BreedingStationCreateRequestData = Pick<
  BreedingStationProps,
  'name' | 'init_date' | 'final_date'
>

export type BreedingStationUpdateRequestData = Pick<
  BreedingStationProps,
  'init_date' | 'final_date' | 'is_active'
>

export type BreedingGroupProps = {
  id: number
  breeding_station_id: number
  breeding_station_name: string
  is_active: boolean
  farm_id: number
  farm_name: string
} & Omit<BreedingStationProps, 'breeding_group' | 'hormonal_protocol'>

export type BreedingGroupDraftProps = {
  breeding_station: SelectItemProps
  farm_id: SelectItemProps
  name: string
  init_date: string
  final_date: string
  id?: number
}

export type BreedingGroupStateProps = {
  group: BreedingGroupDraftProps
  animals: {
    males: AnimalListProps[]
    females: AnimalListProps[]
  }
}

export type BreedingGroupDetailsProps = {
  id: number
  breeding_station_id: number
} & Omit<
  BreedingStationProps,
  'breeding_group' | 'hormonal_protocol' | 'females' | 'males'
>

export type BreedingGroupFilterProps = {
  animal_name: string
  breeding_group_id: number
  init_date: string
  final_date: string
  sector_id: number
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type BreedingStationFilterProps = {
  animal_name: string
  init_date: string
  final_date: string
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type BreedingGroupCreateRequestData = {
  breeding_station: SelectItemProps
  farm_id: SelectItemProps
} & Pick<BreedingStationProps, 'name' | 'init_date' | 'final_date'>

export type BreedingGroupCreateRequestFormatData = {
  breeding_station_id: number
  farm_id: number
} & Omit<BreedingGroupCreateRequestData, 'breeding_station'>

export type BreedingGroupReadResponseData = {
  items: BreedingGroupProps[]
} & DefaultPagination

export type BreedingServiceDetailsProps = {
  males: BreedingServiceAnimalProps[]
  females: BreedingServiceAnimalProps[]
} & Omit<BreedingStationProps, 'males' | 'females'> &
  Omit<BreedingGroupProps, 'males' | 'females'>

export type BreedingGroupAnimalResponseData = {
  items: BreedingServiceAnimalProps[]
} & DefaultPagination

export type BreedingStationAnimalResponseData = {
  items: BreedingServiceAnimalProps[]
} & DefaultPagination

export type BreedingStationReportResponse = {
  breeding_station_id: number
  breeding_station_name?: string
  init_date: Date
  final_date: Date
  is_active: boolean
  animal_id?: number
  animal_sex?: string
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
}

export type DiagnosticAnimal = {
  id: number
  status: boolean
  diagnostic_date: string
  insemination_date: string
  gestation_days: number
  birth_forecast: string
  type: string
  diagnostics_group_id: number
  animal_id: number
  animal?: Partial<AnimalListProps>
}

export type Diagnostic = Partial<
  DiagnosticAnimal & { error_message?: string; [key: string]: unknown }
>

export type DiagnosticProps = {
  id: number
  date: string
  responsible: string
  diagnostics_count?: number
  diagnostics?: DiagnosticAnimal[]
}

export type Diagnostics = Diagnostic[]

export type DiagnosticAnimalReadResponseProps = {
  items: Diagnostics
} & DefaultPagination

export type DiagnosticsHeaderInfo = {
  responsible: string
  date: string
}

export type DiagnosticRow = {
  stock_number?: string
  electronic_eartag?: string
  diagnostic_date?: string
  status?: SelectItemProps
  insemination_date?: string
  type?: string
  gestation_days?: number
  birth_forecast?: string
  id?: number
  animal_id?: number
}

export const POSITIVE_OPTION = { label: 'Positivo', value: 'positivo' }
export const NEGATIVE_OPTION = { label: 'Negativo', value: 'negativo' }

export type DiagnosticReadResponseData = {
  items: DiagnosticProps[]
} & DefaultPagination

export type DiagnosticCreateData = {
  status: boolean
  diagnostic_date: string
  animal_id: number
  gestation_days: number
}

export type FailedDiagnosticProps = Record<number, string>

export type DiagnosticResponseData = {
  fail: FailedDiagnosticProps[]
  success: DiagnosticAnimal[]
}

export type BreedingLoadingState = {
  female: boolean
  male: boolean
}

export type ImportedBreedingGroupAnimalsProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  [index: number]: string
}

export type BreedHook = {
  breeds: BreedProps[]
}

export type DiagnosticCreateRequestData = Pick<
  DiagnosticProps,
  'responsible' | 'date'
>

export type ImportedDiagnosisProps = {
  stock_number: string
  electronic_eartag: string
  birth_number: string
  diagnostic_date: string
  status: string
  [index: number]: string
}

export type GetDiagnosticsFilters = {
  electronic_eartag?: string
  birth_number?: string
  stock_number?: string
  name?: string
  status?: string
  sector_id?: string
  init_date?: string
  final_date?: string
}

export type UpdateDiagnosticInputs = {
  groupId: number
  diagnosticId: number
  status: boolean
  diagnostic_date: string
  gestation_days: number
}

export type DiagnosticTypeResponse = {
  type: string
  gestation_days: number
  birth_forecast: string
  insemination_date: string
}

export type DiagnosticReportResponse = {
  diagnostic_group_id: number
  diagnostic_group_date: Date
  responsible?: string
  animal_id: number
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
  diagnostic_id: number
  diagnostic_status: boolean
  diagnostic_date: Date
  diagnostic_type?: string
  insemination_date?: Date
  gestation_days?: number
  birth_forecast?: Date
}

export type DiagnosticGroupHookProps = {
  filters: GetDiagnosticsFilters
  page?: number
}

export type BreedingGroupReportResponse = {
  breeding_group_id: number
  breeding_group_name?: string
  init_date: Date
  final_date: Date
  is_active: boolean
  animal_id?: number
  animal_sex?: string
  stock_number?: string
  electronic_eartag?: string
  birth_number?: string
  last_calving?: Date
  reproductive_status?: string
}
