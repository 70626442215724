import React from 'react'

import { Chip, Typography, TypographyVariant } from 'components/atoms'

import { useFarm } from 'app/core/hooks'
import {
  IAnimalAdditionalInformation,
  IAnimalBasicInformation,
} from 'app/core/types/animal'
import { dateTimeFormat } from 'app/core/utils'

import styles from './styles.module.scss'
import { BreedChip } from 'components/molecules/breed-chip'

interface IAnimalBasicInfoProps {
  additionalInformation: IAnimalAdditionalInformation
  basicInfo: IAnimalBasicInformation
  isCompositeBreed: boolean
}

const AnimalBasicInfo: React.FC<IAnimalBasicInfoProps> = ({
  additionalInformation,
  basicInfo,
  isCompositeBreed,
}) => {
  const { farms } = useFarm()

  const breedsLength = basicInfo.breed.length
  const breeds = basicInfo.breed

  const farmName =
    farms && farms.find(farm => farm.id === basicInfo.sector?.farm_id)?.name

  return (
    <div className={styles.basicInfo}>
      <div>
        <Typography
          text="Nascimento"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Typography
          text={dateTimeFormat(basicInfo.birth_date)}
          variant={TypographyVariant.p}
        />
      </div>
      <div>
        <Typography
          text="Nº de nascimento"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Typography
          text={basicInfo.birth_number || 'Sem número'}
          variant={TypographyVariant.p}
          className={basicInfo.birth_number ? '' : styles.empty}
        />
      </div>
      {additionalInformation.series && (
        <div>
          <Typography
            text="Série"
            variant={TypographyVariant.caption3}
            altColor
          />
          <Typography
            text={additionalInformation.series}
            variant={TypographyVariant.p}
            className={styles.chipAlternate}
          />
        </div>
      )}
      <div>
        <Typography text="Raça" variant={TypographyVariant.caption3} altColor />
        <BreedChip breeds={breeds} isCompositeBreed={isCompositeBreed} />
      </div>
      <div>
        <Typography
          text="Pelagem"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Chip
          label={basicInfo.cowhide_color}
          selected={false}
          className={styles.chipAlternate}
        />
      </div>
      <div>
        <Typography
          text="Fazenda"
          variant={TypographyVariant.caption3}
          altColor
        />
        {farmName ? (
          <Chip
            label={farmName}
            selected={false}
            className={styles.chipAlternate}
          />
        ) : (
          '-'
        )}
      </div>
      <div>
        <Typography
          text="Setor"
          variant={TypographyVariant.caption3}
          altColor
        />
        <Chip
          label={basicInfo.sector?.name}
          selected={false}
          className={styles.chipAlternate}
        />
      </div>
    </div>
  )
}

export { AnimalBasicInfo }
