import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { useForm } from 'components/organisms'

import {
  useBreedingGroup,
  useBreedingStation,
  useFarm,
  useSector,
} from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { BreedingGroupFilterProps } from 'app/core/types/breeding'
import { isEndDateBeforeStartDate } from 'app/core/utils'
import { Messages } from 'config/messages'

import { BreedingGroupHistoryTemplate } from './template'

const yupTestName = 'isFinalDateLaterThanInitDate'

const validationSchema = yup.object({
  init_date: yup.string(),
  final_date: yup
    .string()
    .test(
      yupTestName,
      Messages.DATE_FINAL_INVALID_RANGE,
      function (value: string | undefined) {
        const { init_date } = this.parent
        return isEndDateBeforeStartDate(init_date, value)
      }
    ),
})

const BreedingGroupHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<BreedingGroupFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteBreedingGroupId, setDeleteBreedingGroupId] = useState<number>()

  const { activeFarmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()
  const { stationsDroplistOptions } = useBreedingStation({})

  const femaleToMaleProportion = 20

  const {
    readAllGroups,
    groups,
    deleteGroup,
    isLoading,
    isLoadingExport,
    exportBreedingGroups,
  } = useBreedingGroup({ filters, page })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterGroups = async (data: unknown): Promise<void> => {
    const dataCopy = data as BreedingGroupFilterProps

    setPage(1)
    setFilters(dataCopy)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteBreedingGroupId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteBreedingGroupId) {
      await deleteGroup(deleteBreedingGroupId)

      handleDeleteModalClose()
    }
  }, [deleteBreedingGroupId, deleteGroup])

  const history = useHistory<NavigateList>()

  const { Form, formState, reset } = useForm({
    onSubmit: handleFilterGroups,
    validationSchema,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
    setFilters(undefined)
  }

  const handleCreateClick = (): void => {
    history.push(NavigateList.breedingGroupCreate)
  }

  const handleDetailsClick = (groupId: number): void => {
    history.push(`${NavigateList.breedingGroupDetails}${groupId}`)
  }

  useEffect(() => {
    readAllGroups()
  }, [readAllGroups])

  return (
    <BreedingGroupHistoryTemplate
      groups={groups}
      currentPage={page}
      setCurrentPage={setPage}
      handleToggleDrawer={handleToggleDrawer}
      femaleToMaleProportion={femaleToMaleProportion}
      isDrawerOpen={isDrawerOpen}
      isLoading={isLoading}
      deleteBreedingGroupId={deleteBreedingGroupId}
      setDeleteBreedingGroupId={setDeleteBreedingGroupId}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      isLoadingExport={isLoadingExport}
      onExportClick={exportBreedingGroups}
      form={Form}
      onCreateClick={handleCreateClick}
      activeFilters={activeFilters}
      onDetailsClick={handleDetailsClick}
      onResetClick={resetFilters}
      farms={activeFarmsDroplist ?? []}
      sectors={sectorsDroplist ?? []}
      breedingStations={stationsDroplistOptions ?? []}
    />
  )
}

export { BreedingGroupHistory }
