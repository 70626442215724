import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { debounce } from 'lodash-es'
import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useElectronicEartag } from 'app/core/hooks/electronic-eartag'
import { NavigateList } from 'app/core/routes/routes'
import {
  AnimalFilterProps,
  ElectronicEartagCreateProps,
} from 'app/core/types/animal'
import { FilterSections } from 'app/core/types/filters'
import { FILTER_ANIMALS_SIZE } from 'app/core/types/system'
import {
  addToast,
  downloadFile,
  generateXlsxTemplate,
  sortAnimalsByError,
  validateAndUpdateAnimals,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { electronicEartagCreateColumns } from '../tables'
import { ElectronicEartagCreateTemplate } from './template'

const ElectronicEartagCreate: React.FC = () => {
  const history = useHistory<NavigateList>()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])
  const [filters, setFilters] = useState<Partial<AnimalFilterProps>>()

  const {
    animals,
    isLoading,
    removeAnimal,
    searchAnimals,
    setAnimals,
    xlsxToAnimals,
  } = useElectronicEartag({ page })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateField = useCallback(
    debounce(
      async (index: number, field: string, value: unknown): Promise<void> => {
        setAnimals(prevState =>
          prevState.map((item, i) => {
            if (i !== index) {
              return item
            }

            const updatedRow = {
              ...item,
              [field]: value,
            }

            return updatedRow
          })
        )
      },
      500
    ),
    []
  )

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const updatedFilters = {
        ...filters,
        size: FILTER_ANIMALS_SIZE,
        is_active: 'true',
      } as AnimalFilterProps

      setFilters(updatedFilters)

      handleToggleDrawer()
    },
    []
  )

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const columns = useMemo<ColumnDef<ElectronicEartagCreateProps>[]>(
    () => electronicEartagCreateColumns(removeAnimal, updateField),
    [removeAnimal, updateField]
  )

  const table = useReactTable({
    data: (animals?.items as ElectronicEartagCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'sector_id',
      'sex',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
      'association_animal',
    ],
    reproductionSection: ['weaning_date_init', 'weaning_date_end', 'aptitude'],
  }

  const handleChange = useCallback(
    (section: string[] | undefined): void => {
      if (section) {
        setActiveFilterSection(section)
      }
    },
    [setActiveFilterSection]
  )

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    handleImportAnimals(selectedFile)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        const templateFile = generateXlsxTemplate([
          [
            'Nº de Plantel',
            'Nº de Brinco eletrônico',
            'Nº de Nascimento',
            'Novo Nº de Brinco eletrônico',
          ],
          ['', '', '', ''],
        ])

        downloadFile({
          data: templateFile,
          fileName: 'template-brinco-eletronico',
        })
      },
    },
  ]

  const handleImportAnimals = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    try {
      const importedAnimals = await xlsxToAnimals(
        selectedFile.filesContent[0].content
      )

      setAnimals(prevState => [
        ...prevState,
        ...sortAnimalsByError(importedAnimals),
      ])

      addToast({
        message: Messages.ANIMALS_IMPORT_SUCCESSFUL,
        type: 'success',
      })
    } catch (e) {
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const handleGoToReview = (): void => {
    const requiredFields = ['id', 'new_electronic_eartag']

    if (animals?.items.length === 0) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    validateAndUpdateAnimals(
      animals?.items || [],
      requiredFields,
      updatedAnimals => {
        setAnimals(updatedAnimals)

        if (updatedAnimals.some(animal => animal.error_message)) {
          addToast({ message: Messages.INVALID_ANIMALS })
          return
        }

        history.push(NavigateList.electronicEartagReview)
      }
    )
  }

  useEffect(() => {
    if (filters) {
      searchAnimals(filters)
    }
  }, [filters, searchAnimals])

  return (
    <ElectronicEartagCreateTemplate
      animals={animals}
      filterSections={filterSections}
      setActiveFilterSection={setActiveFilterSection}
      handleChange={handleChange}
      handleDisabled={handleDisabled}
      handleGoToReview={handleGoToReview}
      handleToggleDrawer={handleToggleDrawer}
      headerSelectButtonItems={selectButtonItems}
      isDrawerOpen={isDrawerOpen}
      isLoading={isLoading}
      onSubmit={handleFilterAnimal}
      page={page}
      setPage={setPage}
      table={table}
    />
  )
}

export { ElectronicEartagCreate }
