import { useCallback, useEffect, useState } from 'react'

import { getAbczReport, getReadAllItems } from 'app/core/services/abcz'
import { AbczFilterProps, AbczReadResponseData } from 'app/core/types/abcz'
import { AbczHook, AbczHookProps } from 'app/core/types/hooks'
import { DEFAULT_ITEMS_PER_PAGE_DETAILS } from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  downloadFile,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useAbcz = ({ filters, page, size }: AbczHookProps): AbczHook => {
  const initialState = {
    items: [],
    total: 0,
    pages: 0,
    size: 0,
    page: 1,
  }

  const [items, setItems] = useState<AbczReadResponseData>(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllItems = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllItems(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_DETAILS
      )

      setItems(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const exportItems = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getAbczReport(filters as Partial<AbczFilterProps>)

      const items = data.map(item => ({
        'Tipo de Comunicação': getValueOrNull(item.type),
        Data: getFormattedDateValueOrNull(item.date),
        'Número ABCZ': getValueOrNull(item.document_identifier),
        'Status da ABCZ':
          item.status_message || Messages.ABCZ_ITEM_PENDING_STATUS,
        'Nº de Plantel': getValueOrNull(item.stock_number),
        'Nº de Nascimento': getValueOrNull(item.birth_number),
        'Brinco eletrônico': getValueOrNull(item.electronic_eartag),
      }))

      const blob = arrayToCsv(items, ';')

      downloadFile({
        data: blob,
        fileName: `abcz-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.ABCZ_ITEMS_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  useEffect(() => {
    readAllItems()
  }, [readAllItems])

  return {
    exportItems,
    items,
    isLoading,
    isLoadingExport,
  }
}

export { useAbcz }
