import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useHandlings } from 'app/core/hooks/handling'
import { NavigateList } from 'app/core/routes/routes'

import { handlingListColumns } from '../tables'
import HandlingReviewTemplate from './template'

const HandlingReview: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { savedHandling, savedHandlingSector, createHandling, isLoading } =
    useHandlings()

  const table = useReactTable({
    data: savedHandling?.handlings || [],
    columns: handlingListColumns({ handlingType: savedHandling?.type }),
    getCoreRowModel: getCoreRowModel(),
  })

  if (!savedHandling || !savedHandlingSector) {
    history.push(NavigateList.animalList)
    return <></>
  }

  const handleCreateHandling = async (): Promise<void> => {
    await createHandling()

    history.push(`${NavigateList.handlingFeedback}`)
  }

  return (
    <>
      {savedHandling && (
        <HandlingReviewTemplate
          handling={savedHandling}
          table={table}
          handleCreateHandling={handleCreateHandling}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export { HandlingReview }
