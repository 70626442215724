import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useSchedules } from 'app/core/hooks'
import { useBirth } from 'app/core/hooks/births'
import { NavigateList } from 'app/core/routes/routes'
import { AnimalFilterProps } from 'app/core/types/animal'
import { ABORTION_TYPES, BirthCreateProps } from 'app/core/types/birth'
import { FilterSections } from 'app/core/types/filters'
import { StorageKeys } from 'app/core/types/storage'
import {
  addToast,
  validateAndUpdateAnimals,
  validateRequiredField,
  validateSeries,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { birthCreateColumns, birthEditableColumns } from '../tables'
import { BirthCreateTemplate } from './template'

const BirthCreate: React.FC = () => {
  const history = useHistory<NavigateList>()

  const { animals, setAnimals, addAnimal } = useBirth({})
  const { schedulesDroplist } = useSchedules({ size: 10 })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])

  const handleGoToReview = (): void => {
    const requiredFields = ['date', 'type']

    if (animals.length === 0) {
      addToast({ message: Messages.ANIMAL_NOT_ADDED })
      return
    }

    validateAndUpdateAnimals(
      animals,
      requiredFields,
      updatedAnimals => {
        setAnimals(updatedAnimals)

        if (updatedAnimals.some(animal => animal.error_message)) {
          addToast({ message: Messages.INVALID_ANIMALS })
          return
        }

        const cleanErrorMessages = updatedAnimals.map(e => {
          delete e.error_message
          return e
        })

        localStorage.setItem(
          StorageKeys.birth_review,
          JSON.stringify(cleanErrorMessages)
        )

        history.push(NavigateList.birthReview)
      },
      (animal, invalidFields) => {
        const isNotAbortion =
          animal.type && !ABORTION_TYPES.includes(animal.type)

        if (animal.mother_belongs_to_association && isNotAbortion) {
          validateRequiredField(animal, 'series', invalidFields)
        }

        const { errorMessage: hasSeriesError } = validateSeries(
          animal.series,
          true
        )
        if (hasSeriesError) {
          invalidFields.push('series')
        }

        if (isNotAbortion) {
          validateRequiredField(animal, 'birth_number', invalidFields)
          validateRequiredField(animal, 'name', invalidFields)
          validateRequiredField(animal, 'sex', invalidFields)
          validateRequiredField(animal, 'birth_weight', invalidFields)
        }
      }
    )
  }

  const defaultColumn = useMemo<Partial<ColumnDef<BirthCreateProps>>>(
    () => birthEditableColumns,
    []
  )

  const columns = useMemo<ColumnDef<BirthCreateProps>[]>(
    () => birthCreateColumns({ animals, setAnimals }),
    [animals, setAnimals]
  )

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterBirth = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const updatedFilters = {
        ...filters,
        is_active: 'true',
      } as AnimalFilterProps

      addAnimal(updatedFilters)
      handleToggleDrawer()
    },
    [addAnimal]
  )

  const table = useReactTable({
    data: animals || [],
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        setAnimals(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  useEffect(() => {
    localStorage.setItem(StorageKeys.birth_review, JSON.stringify(animals))
  }, [animals])

  const filterSections: FilterSections = {
    generalInfoSection: [
      'sector_id',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
    ],

    additionalInfoSection: [
      'female_status',
      'insemination_date_init',
      'insemination_date_end',
      'birth_date_init',
      'birth_date_end',
      'birth_forecast_start',
      'birth_forecast_end',
      'iatf_schedule_id',
    ],
  }

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = useCallback(
    (section: string[] | undefined): void => {
      if (section) {
        setActiveFilterSection(section)
      }
    },
    [setActiveFilterSection]
  )

  return (
    <BirthCreateTemplate
      handleGoToReview={handleGoToReview}
      animals={animals}
      table={table}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterBirth}
      schedulesDroplist={schedulesDroplist}
      setActiveFilterSection={setActiveFilterSection}
      handleChange={handleChange}
      handleDisabled={handleDisabled}
      filterSections={filterSections}
    />
  )
}

export { BirthCreate }
