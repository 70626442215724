import { ColumnDef } from '@tanstack/react-table'

import { RemoveButton } from 'components/molecules'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { ElectronicEartagCreateProps } from 'app/core/types/animal'

import styles from '../create/styles.module.scss'

const electronicEartagListColumns = (
  handleRemoveAnimal: (index: number) => void,
  isFeedback?: boolean
): ColumnDef<ElectronicEartagCreateProps, unknown>[] => [
  {
    header: '#',
    accessorKey: 'id',
    cell: (info): JSX.Element => (
      <span className={styles.orderCell}>{info.row.index + 1}</span>
    ),
  },
  {
    header: 'Nº de Plantel',
    accessorKey: 'stock_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Nascimento',
    accessorKey: 'birth_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Novo Nº de Brinco Eletrônico',
    accessorKey: 'new_electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    id: 'delete',
    cell: (info): JSX.Element =>
      !isFeedback ? (
        <RemoveButton
          itemId={info.row.original.id as number}
          handleRemoveItem={(): void => handleRemoveAnimal(info.row.index)}
        />
      ) : (
        <></>
      ),
  },
]

export { electronicEartagListColumns }
