import { Table } from '@tanstack/react-table'
import React from 'react'

import { Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { ReproductionLayout } from 'components/templates'

import { formattedBreedingStationName } from 'app/core/hooks/breeding/group/helpers'
import { AnimalListProps, AnimalSexPlural } from 'app/core/types/animal'
import { BreedingGroupDraftProps } from 'app/core/types/breeding'
import { breedingGroupIdFormat } from 'app/core/utils'

type BreedingGroupFeedbackTemplateProps = {
  group: BreedingGroupDraftProps
  females?: AnimalListProps[]
  tableFemales: Table<AnimalListProps>
  males?: AnimalListProps[]
  tableMales: Table<AnimalListProps>
  handleFinish: () => void
  exportFailedAnimals: () => void
  failedAnimals: AnimalListProps[]
}

const BreedingGroupFeedbackTemplate: React.FC<BreedingGroupFeedbackTemplateProps> =
  ({
    group,
    females,
    tableFemales,
    males,
    tableMales,
    handleFinish,
    exportFailedAnimals,
    failedAnimals,
  }) => {
    const defaultBoxedTableProps = {
      disableLastCellNavigation: true,
      noDataMessage: 'Nenhum animal inserido com sucesso.',
    }

    return (
      <>
        {group && (
          <>
            <ReproductionLayout
              title={group.name}
              initialDate={group.init_date}
              finalDate={group.final_date}
              breedingStationId={Number(group.breeding_station.value)}
              breedingStationName={formattedBreedingStationName(
                group.breeding_station.label
              )}
              headerLargeButtonLabel="Finalizar"
              headerLargeButtonAction={handleFinish}
              farmId={Number(group.farm_id.value)}
              farmName={group.farm_id.label}
              {...(failedAnimals.length > 0 && {
                headerButtonAction: exportFailedAnimals,
                headerButtonLabel: 'Planilha de inconsistências',
                headerButtonIcon: <Icon name={IconNames['alert']} />,
              })}
              {...(group.id && {
                id: breedingGroupIdFormat(group.id),
              })}
            >
              {females && (
                <BoxedTable
                  title={`${AnimalSexPlural.female} inseridas com sucesso`}
                  table={tableFemales}
                  data={females}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
              {males && (
                <BoxedTable
                  title={`${AnimalSexPlural.male} inseridos com sucesso`}
                  table={tableMales}
                  data={males}
                  isTableHeaderSticky
                  {...defaultBoxedTableProps}
                />
              )}
            </ReproductionLayout>
          </>
        )}
      </>
    )
  }

export { BreedingGroupFeedbackTemplate }
