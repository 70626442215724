import { Table } from '@tanstack/react-table'
import React from 'react'

import { ButtonIconPosition, Icon, IconNames } from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { BirthCreateProps } from 'app/core/types/birth'

type BirthReviewTemplateProps = {
  handleAnimals: () => void
  animals: BirthCreateProps[]
  table: Table<BirthCreateProps>
  isLoading: boolean
}

const BirthReviewTemplate: React.FC<BirthReviewTemplateProps> = ({
  handleAnimals,
  animals,
  table,
  isLoading,
}) => (
  <>
    {animals && (
      <BoxedLayout
        backButton
        title="Revisar cadastro de partos"
        headerLargeButtonAction={handleAnimals}
        headerLargeButtonLabel="Salvar"
        headerLargeButtonIconPosition={ButtonIconPosition.right}
        headerLargeButtonIcon={<Icon name={IconNames['chevron-forward']} />}
        headerLargeButtonLoading={isLoading}
        date={new Date().toISOString()}
        isChildrenSticky
      >
        <BoxedTable
          data={animals}
          table={table}
          isTableHeaderSticky
          title="Partos"
          quantityLabel="Entrada"
          disableNavigation
        />
      </BoxedLayout>
    )}
  </>
)

export { BirthReviewTemplate }
