export enum StorageKeys {
  auth_token = 'authToken',
  handling_review = 'handlingReview',
  handling_review_failed = 'handlingReviewFailed',
  handling_farm_review = 'handlingFarmReview',
  handling_sector_review = 'handlingSectorReview',
  animals_review = 'animalsReview',
  animals_sector_review = 'animalsSectorReview',
  animals_last_review = 'animalsLastReview',
  animals_sector_last_review = 'animalsSectorLastReview',
  animals_date_last_review = 'animalsDateLastReview',
  animals_feedback_positive = 'animalsFeedbackPositive',
  animals_feedback_negative = 'animalsFeedbackNegative',
  shutdown_review = 'shutdownReview',
  shutdown_type_review = 'shutdownTypeReview',
  shutdown_last_review = 'shutdownLastReview',
  shutdown_type_last_review = 'shutdownTypeLastReview',
  shutdown_date_last_review = 'shutdownDateLastReview',
  shutdown_descriptions = 'shutdownDescriptions',
  shutdown_review_failed = 'shutdownReviewFailed',
  birth_review = 'birthReview',
  birth_review_failed = 'birthReviewFailed',
  collective_movements_review = 'collectiveMovementsReview',
  collective_movements_review_failed = 'collectiveMovementsReviewFailed',
  collective_movements_review_info = 'collectiveMovementsReviewInfo',
  collective_movements_feedback_positive = 'collectiveMovementsFeedbackPositive',
  diagnostics_review = 'diagnosticsReview',
  diagnostics_review_info = 'diagnosticsReviewInfo',
  diagnostics_review_failed = 'diagnosticsReviewFailed',
  inseminations_review = 'inseminationsReview',
  inseminations_review_failed = 'inseminationsReviewFailed',
  electronic_eartag_review = 'electronicEartagReview',
  electronic_eartag_review_failed = 'electronicEartagReviewFailed',
  stock_number_review = 'stockNumberReview',
  stock_number_review_failed = 'stockNumberReviewFailed',
  breedingGroup = 'breedingGroup',
  breedingGroupFailed = 'breedingGroupFailed',
}
