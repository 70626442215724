import { useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import { SelectedFiles } from 'use-file-picker'
import * as XLSX from 'xlsx'

import {
  deleteBreedingGroup,
  getBreedingGroupReport,
  getReadAllAnimals,
  getReadAllBreedingGroups,
  getReadBreedingGroup,
  getReadBreedingGroupFemales,
  getReadBreedingGroupMales,
  postAddGroupAnimal,
  postCreateBreedingGroup,
  postRemoveBreedingGroupAnimal,
} from 'app/core/services'
import {
  AnimalFilterProps,
  AnimalListProps,
  AnimalRequestData,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingGroupCreateRequestFormatData,
  BreedingGroupFilterProps,
  BreedingGroupProps,
  BreedingGroupReadResponseData,
  BreedingGroupStateProps,
  BreedingLoadingState,
  ImportedBreedingGroupAnimalsProps,
} from 'app/core/types/breeding'
import {
  BreedingGroupAnimalHook,
  BreedingGroupHook,
  BreedingGroupHookProps,
} from 'app/core/types/hooks'
import { StorageKeys } from 'app/core/types/storage'
import {
  DEFAULT_ITEMS_PER_PAGE_DETAILS,
  DEFAULT_ITEMS_PER_PAGE_LIST,
  FILTER_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  breedingGroupIdFormat,
  dateForFileName,
  dateTimeISOFormat,
  downloadFile,
  generateXlsxTemplate,
  getAnimalSex,
  getFormattedDateValueOrNull,
  getPlural,
  getValueOrNull,
  handleHttpError,
  parseStringFromSheet,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  BreedingAnimalReportFactory,
  BreedingAnimalReportType,
  createGetBreedingAnimalReportParams,
  exportBreedingAnimals,
} from '../breeding-animal-report-factory'
import { getBreedingGroup, setBreedingGroup } from './helpers'

const useBreedingGroup = ({
  groupId,
  filters,
  page,
  size,
}: BreedingGroupHookProps): BreedingGroupHook => {
  const savedFailedAnimals = JSON.parse(
    localStorage.getItem(StorageKeys.breedingGroupFailed) as string
  ) as AnimalListProps[]

  const [failedAnimals, setFailedAnimals] = useState<AnimalListProps[]>(
    () => savedFailedAnimals || []
  )
  const [groups, setGroups] = useState<BreedingGroupReadResponseData>()
  const [group, setGroup] = useState<BreedingGroupProps>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllGroups = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllBreedingGroups(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      if (data.total === 0) {
        addToast({
          message: Messages.BREEDING_GROUP_NOT_FOUND,
          type: 'warning',
        })
      }

      setGroups(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const readGroup = useCallback(async (): Promise<void> => {
    if (groupId) {
      try {
        const data = await getReadBreedingGroup(groupId)
        setGroup(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [groupId])

  const addGroup = useCallback(
    async ({ animals, group }: BreedingGroupStateProps): Promise<void> => {
      if (group.breeding_station.value) {
        setIsLoading(true)

        const request = {
          name: group.name,
          breeding_station_id: Number(group.breeding_station.value),
          init_date: dateTimeISOFormat(group.init_date),
          final_date: dateTimeISOFormat(group.final_date),
          farm_id: Number(group.farm_id.value),
        } as BreedingGroupCreateRequestFormatData

        try {
          const data = await postCreateBreedingGroup(request)

          const breedingGroupId = data.id
          const allAnimals = [...animals.females, ...animals.males]

          const response = await postAddGroupAnimal(
            breedingGroupId,
            allAnimals.map(animal => animal.id)
          )

          const failedAnimals = [] as AnimalListProps[]
          let successfulAnimals = allAnimals

          if (response.length > 0) {
            response.forEach(error => {
              const animalId = Number(Object.keys(error)[0])
              const errorMessage = error[animalId]

              const failedAnimal = allAnimals.find(
                state => state?.id === animalId
              )

              if (failedAnimal) {
                failedAnimals.push({
                  ...failedAnimal,
                  error_message: errorMessage,
                })
              }

              successfulAnimals = [
                ...successfulAnimals.filter(animal => animal.id !== animalId),
              ]
            })

            setFailedAnimals(failedAnimals)

            localStorage.setItem(
              StorageKeys.breedingGroupFailed,
              JSON.stringify(failedAnimals)
            )

            addToast({
              message: `Não foi possível adicionar ${getPlural(
                'animal',
                failedAnimals.length,
                true
              )}. Verifique a planilha de inconsistências e tente novamente.`,
            })
          }

          const successfulMales = successfulAnimals.filter(
            animal => animal.sex === AnimalSexLabel.male
          )
          const successfulFemales = successfulAnimals.filter(
            animal => animal.sex === AnimalSexLabel.female
          )

          const { group } = getBreedingGroup()
          setBreedingGroup(
            {
              ...group,
              id: breedingGroupId,
            },
            successfulMales,
            successfulFemales
          )

          if (successfulAnimals.length > 0) {
            addToast({
              message: Messages.BREEDING_GROUP_CREATE_SUCCESS,
              type: 'success',
            })
          }
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            err.response?.data.message === 'Estação de monta inativa'
          ) {
            addToast({
              message: Messages.BREEDING_GROUP_CREATE_INACTIVE_STATION,
            })

            return
          }

          addToast({ message: Messages.ERROR_MESSAGE })
        } finally {
          setIsLoading(false)
        }
      }
    },
    []
  )

  const deleteGroup = useCallback(
    async (breedingGroupId: number) => {
      if (breedingGroupId) {
        try {
          await deleteBreedingGroup(breedingGroupId)

          readAllGroups()

          addToast({
            message: Messages.BREEDING_GROUP_DELETED_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        }
      }
    },
    [readAllGroups]
  )

  const exportBreedingGroups = useCallback(async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getBreedingGroupReport(
        filters as BreedingGroupFilterProps
      )

      const breedingGroups = data.map(group => ({
        'N° do Grupo de Repasse': breedingGroupIdFormat(
          group.breeding_group_id
        ),
        Nome: getValueOrNull(group.breeding_group_name),
        'Data início': getFormattedDateValueOrNull(group.init_date),
        'Data fim': getFormattedDateValueOrNull(group.final_date),
        Ativo: group.is_active ? 'Sim' : 'Não',
        'Nº de Nascimento': getValueOrNull(group.birth_number),
        'Nº de Plantel': getValueOrNull(group.stock_number),
        'Brinco eletrônico': getValueOrNull(group.electronic_eartag),
        'Data do Ultimo Parto': getFormattedDateValueOrNull(group.last_calving),
        'Status Reprodutivo': getValueOrNull(group.reproductive_status),
        'Sx.': getAnimalSex(group.animal_sex),
      }))

      downloadFile({
        data: arrayToCsv(breedingGroups, ';'),
        fileName: `grupos-repasse-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({
        message: Messages.BREEDING_GROUP_EXPORT_SUCCESS,
        type: 'success',
      })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }, [filters])

  const exportFailedAnimals = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Nascimento',
        'Brinco eletrônico',
        'Inconsistência',
      ],
      ...failedAnimals.map(animal => [
        animal.stock_number,
        animal.birth_number,
        animal.electronic_eartag,
        animal.error_message,
      ]),
    ])

    downloadFile({
      data: templateFile,
      fileName: `grupo-repasse-${dateForFileName()}`,
    })
  }

  const getImportedAnimals = async (
    file: SelectedFiles,
    selectedFarmId: number
  ): Promise<Partial<AnimalListProps>[]> => {
    try {
      setIsLoading(true)

      addToast({
        message: Messages.BREEDING_GROUP_ADDING_ANIMALS,
        type: 'info',
      })

      const data = new Uint8Array(
        file.filesContent[0].content as unknown as ArrayBuffer
      )
      const workbook = XLSX.read(data, {
        type: 'array',
        cellText: false,
        cellDates: true,
      })

      const animals: Partial<AnimalListProps>[] = []

      await Promise.all(
        workbook.SheetNames.map(async sheetName => {
          const worksheet = workbook.Sheets[sheetName]
          const rows = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false,
            raw: true,
          }) as ImportedBreedingGroupAnimalsProps[]

          for (let i = 1; i < rows.length; i++) {
            const row = rows[i]

            const importedStockNumber = parseStringFromSheet(row[0])
            const importedBirthNumber = parseStringFromSheet(row[1])
            const importedElectronicEartag = parseStringFromSheet(row[2])
            const importedSex =
              row[3].trim().toLowerCase() === 'fêmea'
                ? AnimalSexLabel.female
                : AnimalSexLabel.male
            const importedAnimalInfo =
              importedStockNumber ||
              importedBirthNumber ||
              importedElectronicEartag

            const filters = {
              stock_number_or_birth_number_or_electronic_eartag:
                importedAnimalInfo,
            } as AnimalFilterProps

            const animal = (await getReadAllAnimals(filters)).items[0]
            const isAnimalFromDifferentFarm = animal?.farm_id !== selectedFarmId
            const isInactiveAnimal = !animal?.is_active

            if (!animal) {
              animals.push({
                stock_number: importedStockNumber || '',
                birth_number: importedBirthNumber || '',
                electronic_eartag: importedElectronicEartag || '',
                sex: importedSex,
                error_message: Messages.BREEDING_ANIMAL_NOT_FOUND,
              })

              continue
            }

            if (isAnimalFromDifferentFarm || isInactiveAnimal) {
              animals.push({
                ...animal,
                error_message: Messages.BREEDING_ANIMAL_NOT_FOUND,
              })

              continue
            }

            animals.push(animal)
          }
        })
      )

      return animals
    } catch (e) {
      addToast({ message: Messages.ERROR_MESSAGE })
      return []
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    readGroup()
  }, [readGroup])

  return {
    readAllGroups,
    groups,
    group,
    addGroup,
    deleteGroup,
    getImportedAnimals,
    isLoading,
    isLoadingExport,
    exportBreedingGroups,
    failedAnimals,
    exportFailedAnimals,
  }
}

const useBreedingGroupAnimal = ({
  groupId,
  femalePage,
  malePage,
}: BreedingGroupHookProps): BreedingGroupAnimalHook => {
  const { group } = useBreedingGroup({ groupId })

  const [females, setFemales] = useState<BreedingGroupAnimalResponseData>()
  const [males, setMales] = useState<BreedingGroupAnimalResponseData>()
  const [isLoading, setIsLoading] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [filterIsActive, setFilterIsActive] = useState<BreedingLoadingState>({
    female: false,
    male: false,
  })

  const readFemales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (groupId) {
        try {
          const data = await getReadBreedingGroupFemales(
            groupId,
            femalePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )
          setFemales(data)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [groupId, femalePage]
  )

  const readMales = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (groupId) {
        try {
          const data = await getReadBreedingGroupMales(
            groupId,
            malePage || 1,
            DEFAULT_ITEMS_PER_PAGE_DETAILS,
            filters
          )
          setMales(data)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [groupId, malePage]
  )

  const addAnimal = useCallback(
    async (filters: AnimalFilterProps): Promise<void> => {
      if (groupId) {
        try {
          if (filters.sex === AnimalSexLabel.female) {
            setIsLoading({ ...isLoading, female: true })
          } else {
            setIsLoading({ ...isLoading, male: true })
          }

          const newFilters = {
            ...filters,
            size: FILTER_ANIMALS_SIZE,
          }

          const animals = await getReadAllAnimals(
            newFilters,
            1,
            FILTER_ANIMALS_SIZE
          )
          const animalIds = animals?.items.map(animal => animal.id)

          if (!animalIds.length) {
            addToast({
              message: Messages.BREEDING_ANIMAL_NOT_FOUND,
            })

            setIsLoading({ ...isLoading, female: false, male: false })

            return
          }

          addToast({
            message: Messages.BREEDING_GROUP_ADDING_ANIMALS,
            type: 'info',
          })

          const data = await postAddGroupAnimal(groupId, animalIds)

          data?.forEach(animal => {
            const animalId = Object.keys(animal)
            const errorMessage = Object.values(animal)
            addToast({
              message: `${errorMessage}: ${
                animals.items.find(animal => animal.id === Number(animalId))
                  ?.stock_number || animalId
              }`,
            })
          })

          readFemales()
          readMales()
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [groupId, readFemales, readMales, isLoading]
  )

  const removeAnimal = useCallback(
    async (animalId: number): Promise<void> => {
      if (groupId) {
        try {
          setIsLoading(prevState => ({
            ...prevState,
            female: true,
            male: true,
          }))

          await postRemoveBreedingGroupAnimal(groupId, animalId)
          readFemales()
          readMales()

          addToast({
            message: Messages.BREEDING_ANIMAL_REMOVE_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading(prevState => ({
            ...prevState,
            female: false,
            male: false,
          }))
        }
      }
    },
    [groupId, readFemales, readMales]
  )

  const filterAnimals = useCallback(
    async (filters?: AnimalRequestData): Promise<void> => {
      if (groupId) {
        try {
          const newFilters = {
            ...filters,
            size: DEFAULT_ITEMS_PER_PAGE_DETAILS,
          } as AnimalFilterProps

          const sex = filters?.sex

          sex == AnimalSexLabel.female
            ? setIsLoading({ ...isLoading, female: true })
            : setIsLoading({ ...isLoading, male: true })

          sex == AnimalSexLabel.female
            ? await readFemales(newFilters)
            : await readMales(newFilters)

          const filtersWithoutSex = { ...filters } as Record<string, unknown>
          delete filtersWithoutSex['sex']

          setFilterIsActive(prevState => ({
            ...prevState,
            [sex == AnimalSexLabel.female
              ? AnimalSexLabel.female
              : AnimalSexLabel.male]: !!Object.keys(filtersWithoutSex).length,
          }))
        } catch (e) {
          handleHttpError(e)
        } finally {
          setIsLoading({ ...isLoading, female: false, male: false })
        }
      }
    },
    [groupId, readFemales, readMales, isLoading]
  )

  const exportBreedingGroup = async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      const params = createGetBreedingAnimalReportParams(
        groupId as number,
        females?.total ?? 0,
        males?.total ?? 0,
        BreedingAnimalReportType.group,
        group?.name
      )

      const breedingGroupAnimalsFactory = (): BreedingAnimalReportFactory => ({
        getFemales: (
          groupId,
          page,
          total
        ): Promise<BreedingGroupAnimalResponseData> =>
          getReadBreedingGroupFemales(groupId, page, total),
        getMales: (
          groupId,
          page,
          total
        ): Promise<BreedingGroupAnimalResponseData> =>
          getReadBreedingGroupMales(groupId, page, total),
      })

      await exportBreedingAnimals(
        breedingGroupAnimalsFactory(),
        params,
        `grupo-repasse-${dateForFileName()}`
      )
    } catch (e) {
      handleHttpError(e, false)
    } finally {
      setIsLoadingExport(false)
    }
  }

  useEffect(() => {
    readFemales()
    readMales()
  }, [readFemales, readMales])

  return {
    females,
    males,
    addAnimal,
    removeAnimal,
    isLoading,
    filterAnimals,
    filterIsActive,
    isLoadingExport,
    exportBreedingGroup,
  }
}

export { useBreedingGroup, useBreedingGroupAnimal }
