import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { useForm } from 'components/organisms'
import { getDirtyValues } from 'components/organisms/form/helpers'

import { isAdmin } from 'app/core/auth'
import { useAnimal } from 'app/core/hooks'
import { useBirth } from 'app/core/hooks/births'
import { IParamProps, NavigateList } from 'app/core/routes/routes'
import { AnimalParentType, AnimalSexLabel } from 'app/core/types/animal'
import { ABORTION_TYPES, BirthProps, BirthType } from 'app/core/types/birth'
import { Messages } from 'config/messages'

import { BirthUpdateTemplate } from './template'

const BirthUpdate: React.FC = () => {
  const history = useHistory()
  const { birthId } = useParams<IParamProps>()

  const { birth, updateBirth } = useBirth({ birthId })
  const { searchAnimals, updateAnimal } = useAnimal({
    animalId: birth?.calf_id,
  })

  const [birthType] = useState<BirthType>()
  const [breedingGroupMales, setBreedingGroupMales] = useState<
    SelectItemProps[]
  >([])

  const isAbortion =
    (birth?.birth_type && ABORTION_TYPES.includes(birth.birth_type)) || false
  const fatherIsBreedingGroup =
    birth?.father_type === AnimalParentType.breeding_group
  const calfHasChildren = birth?.calf_has_children
  const birthFatherName = birth?.father_name || birth?.father_stock_number || ''

  const canEditBirthFather = useMemo(() => {
    return (isAdmin() && fatherIsBreedingGroup && !calfHasChildren) || false
  }, [fatherIsBreedingGroup, calfHasChildren])

  const getBreedingGroupMales = useCallback(async (): Promise<void> => {
    const males = await searchAnimals({
      sex: AnimalSexLabel.male,
      breeding_group_id: birth?.father_id,
    })

    const breedingGroupMalesOptions = males.items.map(
      ({ id, stock_number }) => ({
        label: stock_number,
        value: id.toString(),
      })
    )

    setBreedingGroupMales(breedingGroupMalesOptions)
  }, [birth?.father_id, searchAnimals])

  const handleUpdateBirth = useCallback(
    async (data: Record<string | number, unknown>): Promise<void> => {
      data.birth_type = birthType
      const updatesFatherId = data.father_id

      if (updatesFatherId) {
        await updateAnimal({
          father_id: Number((data.father_id as SelectItemProps).value),
        })
      }

      await updateBirth(getDirtyValues(data, dirtyFields) as BirthProps)

      history.push(NavigateList.birthHistory)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [birthType, updateBirth, history]
  )

  const validationSchema = isAbortion
    ? yup.object({
        birth_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
      })
    : yup.object({
        calf_birth_weight: yup.string().required(Messages.YUP_REQUIRED_FIELD),
        calf_birth_number: yup.string().required(Messages.YUP_REQUIRED_FIELD),
      })

  const {
    Form,
    formState: { dirtyFields },
    setValue,
  } = useForm({
    onSubmit: handleUpdateBirth,
    validationSchema,
  })

  useEffect(() => {
    if (birth) {
      setValue('calf_sex', birth.calf_sex)
      setValue('calf_cowhide_color', birth.calf_cowhide_color)
    }
  }, [birth, setValue])

  useEffect(() => {
    if (canEditBirthFather) {
      getBreedingGroupMales()
    }
  }, [canEditBirthFather, getBreedingGroupMales])

  if (!birth) {
    return null
  }

  return (
    <BirthUpdateTemplate
      birth={birth}
      birthFatherName={birthFatherName}
      birthType={birthType}
      breedingGroupMales={breedingGroupMales}
      canEditBirthFather={canEditBirthFather}
      form={Form}
      isAbortion={isAbortion}
    />
  )
}

export { BirthUpdate }
