import { CellContext, ColumnDef } from '@tanstack/react-table'

import { AbczStatus, Chip } from 'components/atoms'
import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { HandledAnimalProps, HandlingTypes } from 'app/core/types/handling'
import { IProductProps } from 'app/core/types/products'

import styles from './styles.module.scss'

type HandlingListColumnsProps = {
  handlingType: HandlingTypes
}

const handlingListColumns = ({
  handlingType,
}: HandlingListColumnsProps): ColumnDef<HandledAnimalProps, unknown>[] => [
  {
    header: 'Nº de Nascimento',
    accessorKey: 'birth_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  ...(handlingType === HandlingTypes.weaning_handling
    ? [
        {
          header: 'Nº de Plantel',
          accessorKey: 'stock_number',
        },
        {
          header: 'Série',
          accessorKey: 'series',
        },
      ]
    : []),
  {
    header: 'Peso',
    accessorKey: 'current_weight',
    cell: (info: CellContext<HandledAnimalProps, unknown>): JSX.Element => (
      <span>{info.row.original.current_weight?.toString() || '0'} kg</span>
    ),
  },
  {
    header: 'GMD',
    accessorKey: 'average_daily_gain',
    cell: (info: CellContext<HandledAnimalProps, unknown>): JSX.Element => (
      <span>{info.row.original.average_daily_gain?.toString() || '0'} kg</span>
    ),
  },
  {
    header: 'Produto Aplicado',
    accessorKey: 'product',
    cell: ({ getValue }): JSX.Element => {
      const product = getValue() as IProductProps
      return (
        <ul className={styles.productList}>
          {Object.keys(product).length
            ? Object.keys(product).map((name, i) => (
                <li key={i} className={styles.productListElement}>
                  <Chip label={name} selected={false} />
                </li>
              ))
            : '-'}
        </ul>
      )
    },
  },
  {
    header: 'Quantidade',
    accessorKey: 'product',
    cell: ({ getValue }): JSX.Element => {
      const product = getValue() as IProductProps
      return (
        <ul className={styles.productList}>
          {Object.values(product).length
            ? Object.values(product).map((quantity, i) => (
                <li key={i} className={styles.productListElement}>
                  {quantity ?? (
                    <span className={styles.empty}>
                      Quantidade não preenchida
                    </span>
                  )}
                </li>
              ))
            : '-'}
        </ul>
      )
    },
  },
  {
    header: 'Status abcz',
    accessorKey: 'abcz_status',
    cell: (info: CellContext<HandledAnimalProps, unknown>): JSX.Element => {
      const abcz_status = info.row.original.abcz_status

      if (abcz_status == undefined) return <></>

      return (
        <AbczStatus
          status={abcz_status}
          message={info.row.original.abcz_status_message ?? ''}
        />
      )
    },
  },
]

export { handlingListColumns }
