import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames } from 'components/atoms'

import { useAnimal, useBreedingGroup } from 'app/core/hooks'
import {
  filterNewAnimals,
  getBreedingGroup,
  getDuplicatedAnimals,
  notifyDuplicatedAnimals,
  setBreedingGroup,
} from 'app/core/hooks/breeding/group/helpers'
import { NavigateList } from 'app/core/routes/routes'
import {
  AnimalFilterProps,
  AnimalListProps,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  addToast,
  downloadFile,
  generateXlsxTemplate,
  sortAnimalsByError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import {
  breedingGroupAddFemalesColumns,
  breedingGroupAddMalesColumns,
} from '../tables'
import { BreedingGroupAddAnimalsTemplate } from './template'

const BreedingGroupAddAnimals: React.FC = () => {
  const history = useHistory<NavigateList>()

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const { group, animals } = getBreedingGroup()

  const [males, setMales] = useState<AnimalListProps[]>(animals.males)
  const [females, setFemales] = useState<AnimalListProps[]>(animals.females)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedSex, setSelectedSex] = useState<AnimalSexLabel>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { searchAnimals } = useAnimal({})
  const { getImportedAnimals, isLoading: isLoadingBreedingGroup } =
    useBreedingGroup({})

  const handleAnimals = useCallback(
    (animals: AnimalListProps[]): void => {
      const newMales = animals.filter(
        animal => animal.sex === AnimalSexLabel.male
      )
      const newFemales = animals.filter(
        animal => animal.sex === AnimalSexLabel.female
      )

      const duplicatedMales: AnimalListProps[] = getDuplicatedAnimals(
        newMales,
        males
      )
      const duplicatedFemales: AnimalListProps[] = getDuplicatedAnimals(
        newFemales,
        females
      )

      notifyDuplicatedAnimals([...duplicatedMales, ...duplicatedFemales])

      const sortedMales = sortAnimalsByError([
        ...males,
        ...filterNewAnimals(newMales, males),
      ]) as AnimalListProps[]
      const sortedFemales = sortAnimalsByError([
        ...females,
        ...filterNewAnimals(newFemales, females),
      ]) as AnimalListProps[]

      setMales(sortedMales)
      setFemales(sortedFemales)
    },
    [females, males]
  )

  const handleToggleDrawer = (sex?: AnimalSexLabel): void => {
    if (sex) setSelectedSex(sex)

    setIsDrawerOpen(prevState => !prevState)
  }

  const handleRemoveAnimal = (
    animalId: number,
    animalSex: AnimalSexLabel
  ): void => {
    if (animalSex === AnimalSexLabel.male)
      setMales(prevState => prevState.filter(animal => animal.id !== animalId))

    if (animalSex === AnimalSexLabel.female)
      setFemales(prevState =>
        prevState.filter(animal => animal.id !== animalId)
      )
  }

  const handleImportAnimals = useCallback(
    async (files: SelectedFiles) => {
      const animals = await getImportedAnimals(
        files,
        Number(group.farm_id.value)
      )

      handleAnimals(animals as AnimalListProps[])
    },
    [getImportedAnimals, group, handleAnimals]
  )

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      setIsLoading(true)

      const updatedFilters = {
        ...filters,
        sex: selectedSex,
        is_active: 'true',
        breeding_station_id: Number(group?.breeding_station.value),
        farm_id: Number(group?.farm_id.value),
      } as AnimalFilterProps

      const data = await searchAnimals(updatedFilters)

      setIsLoading(false)

      if (data.items.length === 0) {
        handleToggleDrawer()
        addToast({ message: Messages.BREEDING_ANIMAL_NOT_FOUND })
        return
      }

      handleAnimals(data.items)

      handleToggleDrawer()
    },
    [handleAnimals, selectedSex, group, searchAnimals]
  )

  const handleGoToReview = (): void => {
    const hasMale = males.length > 0
    const hasFemale = females.length > 0

    if (!hasMale || !hasFemale) {
      addToast({ message: Messages.BREEDING_GROUP_ANIMALS_REQUIRED })
      return
    }

    if (
      males.some(animal => animal.error_message) ||
      females.some(animal => animal.error_message)
    ) {
      addToast({ message: Messages.INVALID_ANIMALS })
      return
    }

    setBreedingGroup(group, males, females)

    history.push(NavigateList.breedingGroupReview)
  }

  const tableFemales = useReactTable({
    data: females,
    columns: breedingGroupAddFemalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  const tableMales = useReactTable({
    data: males,
    columns: breedingGroupAddMalesColumns(handleRemoveAnimal),
    getCoreRowModel: getCoreRowModel(),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    await handleImportAnimals(selectedFile)
  }

  const selectButtonItems = useMemo(
    () => [
      {
        name: 'Importar',
        icon: IconNames.upload,
        action: (): void => {
          openFileSelector()
        },
      },
      {
        name: 'Exportar template',
        icon: IconNames.download,
        action: (): void => {
          const templateFile = generateXlsxTemplate([
            [
              'Nº de Plantel',
              'Nº de Nascimento',
              'Nº de Brinco eletrônico',
              'Sexo',
            ],
            ['', '', '', ''],
          ])

          downloadFile({
            data: templateFile,
            fileName: 'template-repasse',
          })
        },
      },
    ],
    [openFileSelector]
  )

  return (
    <BreedingGroupAddAnimalsTemplate
      females={females}
      tableFemales={tableFemales}
      males={males}
      tableMales={tableMales}
      group={group}
      selectedSex={selectedSex}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterAnimal}
      isLoading={isLoading || isLoadingBreedingGroup}
      handleGoToReview={handleGoToReview}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { BreedingGroupAddAnimals }
