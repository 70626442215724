import React from 'react'

import {
  Chip,
  ChipColorVariant,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { AnimalSexLabel } from 'app/core/types/animal'
import { ABORTION_TYPES, BirthProps } from 'app/core/types/birth'

import styles from './styles.module.scss'

type BirthExpandedInfoProps = {
  birth: BirthProps
  onFatherClick: () => void
  onCalfNameClick: () => void
}

const BirthExpandedInfo: React.FC<BirthExpandedInfoProps> = ({
  birth,
  onFatherClick,
  onCalfNameClick,
}) => {
  const isAbortion =
    (birth?.birth_type && ABORTION_TYPES.includes(birth.birth_type)) || false
  const birthFatherName =
    birth?.father_name || birth?.father_stock_number || '-'

  return (
    <div className={styles.expandedSection}>
      <span
        className={styles.expandCardInfoColumn}
        onClick={(): void => onCalfNameClick?.call(this)}
      >
        <span className={styles.caption}>Nome da cria</span>
        <Typography
          text={!birth.calf_name ? '-' : birth.calf_name}
          variant={TypographyVariant.h6}
        />
      </span>

      {birth.calf_sex && (
        <div>
          <span className={styles.caption}>Sexo da cria</span>
          <Chip
            colorVariant={
              birth.calf_sex === AnimalSexLabel.female
                ? ChipColorVariant.sexFemale
                : ChipColorVariant.sexMale
            }
          />
        </div>
      )}

      <div>
        <span className={styles.caption}>Tipo do parto</span>
        <Chip selected={false} label={birth.birth_type} />
      </div>

      {birth?.farm_name && (
        <div>
          <span className={styles.caption}>Fazenda</span>
          <Chip selected={false} label={birth?.farm_name} />
        </div>
      )}

      {birth?.sector_name && (
        <div>
          <span className={styles.caption}>Setor</span>
          <Chip selected={false} label={birth?.sector_name} />
        </div>
      )}

      {birth?.breed && (
        <div>
          <span className={styles.caption}>Raça</span>
          <Chip selected={false} label={birth.breed} />
        </div>
      )}

      {!isAbortion && (
        <div onClick={onFatherClick}>
          <span className={styles.caption}>Pai / Grupo de repasse</span>
          <Typography text={birthFatherName} variant={TypographyVariant.h6} />
        </div>
      )}
    </div>
  )
}

export { BirthExpandedInfo }
