import { SelectItemProps } from 'components/atoms'

import { Messages } from 'config/messages'

import { DefaultPagination } from './system'

export enum AbczSyncType {
  insemination = 'insemination',
  natural_cover = 'natural_cover',
  birth = 'birth',
  shutdown_animal = 'shutdown_animal',
  death_animal = 'death_animal',
  weight = 'weight',
}

export const AbczTypeLabels: Record<AbczSyncType, string> = {
  [AbczSyncType.insemination]: 'Inseminação Artificial',
  [AbczSyncType.natural_cover]: 'Cobertura Natural',
  [AbczSyncType.birth]: 'Parto',
  [AbczSyncType.shutdown_animal]: 'Baixa de animal',
  [AbczSyncType.death_animal]: 'Morte de animal',
  [AbczSyncType.weight]: 'Pesagem',
}

export const abczSyncTypeOptions: SelectItemProps[] = Object.entries(
  AbczTypeLabels
).map(([key, value]) => ({
  label: value,
  value: key,
}))

export enum AbczSyncStatus {
  success = 'success',
  pending = 'pending',
  failed = 'failed',
}

export const AbczStatusLabels: Record<AbczSyncStatus, string> = {
  [AbczSyncStatus.success]: 'Sucesso',
  [AbczSyncStatus.pending]: 'Pendente',
  [AbczSyncStatus.failed]: 'Falha',
}

export const abczSyncStatusOptions: SelectItemProps[] = Object.entries(
  AbczStatusLabels
).map(([key, value]) => ({
  label: value,
  value: key,
}))

export type AbczDataItem =
  | { field: 'status_message'; value: string }
  | { field: string; value: number | string }

export type AbczItemProps = {
  id: number
  document_identifier?: string
  animal_id: number
  date?: string
  data: AbczDataItem[]
  type: AbczSyncType
  status: AbczSyncStatus
  stock_number?: string
  birth_number?: string
  electronic_eartag?: string
  detail_id?: number
}

export const getAbczItemStatusMessage = (data: AbczDataItem[]) => {
  return (
    data.find(item => item.field === 'status_message')?.value.toString() ||
    Messages.ABCZ_ITEM_PENDING_STATUS
  )
}

export type AbczReadResponseData = {
  items: AbczItemProps[]
} & DefaultPagination

export type AbczFilterProps = Partial<AbczItemProps> & {
  farm_id?: number
  sector_id?: number
}

export type AbczItemReportProps = Pick<
  AbczItemProps,
  'document_identifier' | 'stock_number' | 'electronic_eartag' | 'birth_number'
> & {
  date: Date
  type: string
  status: string
  status_message?: string
}
