import React from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'
import { format, parseISO } from 'date-fns'

import {
  AbczStatus,
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { NavigateList } from 'app/core/routes/routes'
import {
  AbczReadResponseData,
  abczSyncStatusOptions,
  AbczSyncType,
  abczSyncTypeOptions,
  AbczTypeLabels,
  getAbczItemStatusMessage,
} from 'app/core/types/abcz'
import { dateToday } from 'app/core/utils'

import styles from './styles.module.scss'

type ABCZHistoryTemplateProps = {
  items: AbczReadResponseData
  isDrawerOpen: boolean
  farmsDroplist: SelectItemProps[] | undefined
  sectorsDroplist: SelectItemProps[] | undefined
  handleToggleDrawer: () => void
  onExportClick: () => Promise<void>
  onSubmitFilter: IFormProps['onSubmit']
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  isLoading: boolean
  isLoadingExport: boolean
}

const ABCZHistoryTemplate: React.FC<ABCZHistoryTemplateProps> = ({
  items,
  isDrawerOpen,
  farmsDroplist,
  sectorsDroplist,
  handleToggleDrawer,
  onExportClick,
  onSubmitFilter,
  page,
  setPage,
  isLoading,
  isLoadingExport,
}) => {
  const history = useHistory()

  const { Form, formState, reset } = useForm({
    onSubmit: onSubmitFilter,
  })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
  }

  const getDetailPage = (
    type: AbczSyncType,
    animalId: number,
    detailId?: number
  ): string => {
    const routeMap: Record<AbczSyncType, string> = {
      [AbczSyncType.insemination]: `${NavigateList.inseminationDetails}${detailId}/`,
      [AbczSyncType.natural_cover]: `${NavigateList.inseminationDetails}${detailId}/`,
      [AbczSyncType.birth]: `${NavigateList.birthHistory}?birthId=${detailId}`,
      [AbczSyncType.shutdown_animal]: `${NavigateList.animalRecord}${animalId}/`,
      [AbczSyncType.death_animal]: `${NavigateList.animalRecord}${animalId}/`,
      [AbczSyncType.weight]: `${NavigateList.handlingDetails}${detailId}/`,
    }

    return routeMap[type] ?? `${NavigateList.animalRecord}${animalId}/`
  }

  const ItemCol: React.FC<{
    className?: string
    title: string
    content?: React.ReactNode
  }> = ({ className, title, content }) => {
    return (
      <div className={classNames(styles.itemCol, className)}>
        <Typography
          text={title}
          variant={TypographyVariant.caption3}
          altColor
        />
        <Typography text={content || '-'} variant={TypographyVariant.h6} />
      </div>
    )
  }

  return (
    <>
      <BoxedLayout
        title="Envio para ABCZ"
        currentPage={page}
        totalPages={items?.pages}
        setCurrentPage={setPage}
        headerButtonLabel="Exportar"
        headerButtonIcon={<Icon name={IconNames.download} />}
        headerButtonAction={onExportClick}
        headerButtonLoading={isLoadingExport}
      >
        {withLoading(
          <>
            <DataCounter
              counter={items?.total}
              counterLabel={'Registros'}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            {items.items?.map(
              ({
                animal_id,
                document_identifier,
                type,
                status,
                birth_number,
                data,
                date,
                electronic_eartag,
                stock_number,
                detail_id,
              }) => {
                const hasDetails = !!detail_id

                return (
                  <div
                    key={document_identifier}
                    className={classNames(
                      styles.item,
                      hasDetails && styles.itemHasDetails
                    )}
                    {...(hasDetails && {
                      onClick: () =>
                        history.push(getDetailPage(type, animal_id, detail_id)),
                    })}
                  >
                    <ItemCol
                      title="Tipo de comunicação"
                      content={AbczTypeLabels[type]}
                    />
                    <ItemCol
                      title="Data"
                      content={format(
                        parseISO(date as string),
                        'dd/MM/yyyy HH:mm'
                      )}
                    />
                    <ItemCol
                      title="Número ABCZ"
                      content={document_identifier}
                    />
                    <ItemCol
                      className={styles.itemColStatus}
                      title="Status ABCZ"
                      content={
                        <AbczStatus
                          status={status}
                          message={getAbczItemStatusMessage(data)}
                        />
                      }
                    />
                    <ItemCol title="Nº de plantel" content={stock_number} />
                    <ItemCol title="Nº de nascimento" content={birth_number} />
                    <ItemCol
                      title="Brinco eletrônico"
                      content={electronic_eartag}
                    />
                    {hasDetails && (
                      <div className={styles.itemCol}>
                        <Button
                          className={styles.openButton}
                          type={ButtonType.ghost}
                          icon={<Icon name={IconNames.folder} />}
                          label="Abrir registro"
                          htmlType="button"
                        />
                      </div>
                    )}
                  </div>
                )
              }
            )}
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {farmsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Fazenda"
                  name="farm_id"
                  options={farmsDroplist}
                />
              </DrawerCol>
            )}
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Número ABCZ"
                name="document_identifier"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.Select
                label="Status ABCZ"
                name="status"
                options={abczSyncStatusOptions}
              />
            </DrawerCol>
            <DrawerCol>
              <Form.Select
                label="Tipo de comunicação"
                name="type"
                options={abczSyncTypeOptions}
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Data da comunicação"
                name="date"
                htmlType="date"
                max={dateToday}
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>
    </>
  )
}

export { ABCZHistoryTemplate }
