import Papa from 'papaparse'

import {
  AbczFilterProps,
  AbczItemReportProps,
  AbczReadResponseData,
  AbczStatusLabels,
  AbczSyncStatus,
  AbczSyncType,
  AbczTypeLabels,
} from 'app/core/types/abcz'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/abcz'

const getReadAllItems = async (
  filters?: Partial<AbczFilterProps>,
  page?: number,
  size?: number
): Promise<AbczReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })

  return response.data
}

const getAbczReport = async (
  filters: Partial<AbczFilterProps>
): Promise<AbczItemReportProps[]> => {
  const response = await http.get(`${baseUrl}/report`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters) },
  })

  const items: AbczItemReportProps[] = []
  const csv = Papa.parse(response.data)

  for (const item of csv.data as string[][]) {
    if (item.length === 1 && item[0] === '') {
      continue
    }

    items.push({
      date: new Date(item[0]),
      type: AbczTypeLabels[item[1] as AbczSyncType],
      status: AbczStatusLabels[item[2] as AbczSyncStatus],
      electronic_eartag: item[3],
      stock_number: item[4],
      birth_number: item[5],
      document_identifier: item[6],
      status_message: item[7],
    })
  }

  return items
}

export { getAbczReport, getReadAllItems }
