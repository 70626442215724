import { Table } from '@tanstack/react-table'
import React from 'react'

import { ButtonIconPosition, ButtonType } from 'components/atoms'
import { CustomModal } from 'components/molecules'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { ShutdownCreateProps } from 'app/core/types/shutdown'
import { Messages } from 'config/messages'

type ShutdownReviewTemplateProps = {
  handleToggleModal: () => void
  handleShutdown: () => void
  modalIsOpen: boolean
  savedShutdown: ShutdownCreateProps[]
  table: Table<ShutdownCreateProps>
  shutdownDate: string
  isLoading: boolean
}

const ShutdownReviewTemplate: React.FC<ShutdownReviewTemplateProps> = ({
  handleToggleModal,
  handleShutdown,
  modalIsOpen,
  savedShutdown,
  table,
  shutdownDate,
  isLoading,
}) => {
  return (
    <BoxedLayout
      title="Baixas de animais"
      headerLargeButtonAction={handleToggleModal}
      headerLargeButtonLabel="Salvar"
      headerLargeButtonIconPosition={ButtonIconPosition.right}
      headerLargeButtonLoading={isLoading}
      date={shutdownDate}
      isChildrenSticky
      backButton
    >
      <BoxedTable
        data={savedShutdown}
        table={table}
        title="Animais"
        quantityLabel="Selecionado"
        isTableHeaderSticky
        isLoading={isLoading}
      />

      <CustomModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleToggleModal}
        modalHeader="Atenção"
        modalText={Messages.SHUTDOWN_CONFIRMATION}
        primaryButtonLabel="Inativar"
        primaryButtonAction={handleShutdown}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="Cancelar"
        secondaryButtonAction={handleToggleModal}
      />
    </BoxedLayout>
  )
}

export { ShutdownReviewTemplate }
