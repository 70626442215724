import React, { useCallback, useState } from 'react'

import { useFarm, useSector } from 'app/core/hooks'
import { useAbcz } from 'app/core/hooks/abcz'
import { AbczFilterProps } from 'app/core/types/abcz'
import { isSelectItem } from 'app/core/utils'

import { ABCZHistoryTemplate } from './template'

const ABCZHistory: React.FC = () => {
  const { farmsDroplist } = useFarm()
  const { sectorsDroplist } = useSector()

  const [filters, setFilters] = useState<AbczFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { exportItems, items, isLoading, isLoadingExport } = useAbcz({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilter = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const updatedFilters = Object.fromEntries(
        Object.entries(filters).map(([key, value]) =>
          isSelectItem(value) ? [key, value.value] : [key, value]
        )
      )

      setPage(1)
      setFilters(updatedFilters)
      handleToggleDrawer()
    },
    []
  )

  return (
    <ABCZHistoryTemplate
      items={items}
      isDrawerOpen={isDrawerOpen}
      farmsDroplist={farmsDroplist}
      sectorsDroplist={sectorsDroplist}
      handleToggleDrawer={handleToggleDrawer}
      onExportClick={exportItems}
      onSubmitFilter={handleFilter}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
    />
  )
}

export { ABCZHistory }
